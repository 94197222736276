import { makeStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import React, { Fragment } from 'react';
import { EnquiryFormData } from './EnquiryForm';
import JourneyRow from './JourneyRow';
import WeeklyRefundRow from './WeeklyRefundRow';
import { ChangeHandler } from '../libs/hooksLib';
import { Journey } from '../queries/journeyQuery';

const useStyles = makeStyles((theme) => ({
  tableJourney: {
    marginBottom: 10
  },
  tableBody: {
    '& td': {
      borderBottom: '0px'
    },
    '& tr:last-child': {
      '& td': {
        borderBottom: '0px solid #e0e0e0'
      }
    },
    '& tr': {
      '& td:first-child': {
        borderLeft: '0px solid #e0e0e0'
      },
      '& td:last-child': {
        borderRight: '0px solid #e0e0e0'
      }
    }
  }
}));

interface JourneyProps {
  key: number;
  journey: Journey;
  setEnquiryJourneys?: any;
  enquiryProps?: {
    formData: EnquiryFormData;
    handleChange: ChangeHandler<EnquiryFormData>;
  };
}

const WeeklyJourney = ({ journey, enquiryProps }: JourneyProps) => {
  const classes = useStyles();

  if (journey) {
    return (
      <Fragment>
        <TableContainer
          data-testid="completedJourney"
          className={classes.tableJourney}
          data-test="completedJourney"
        >
          <Table size="medium">
            <TableBody className={classes.tableBody}>
              <JourneyRow
                enquiryProps={enquiryProps}
                journey={journey}
                isSingleFare={false}
                isResidual={false}
              />
              {journey.totalValueRefunded > 0 && (
                <WeeklyRefundRow
                  refundedTotal={journey.totalValueRefunded}
                ></WeeklyRefundRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Fragment>
    );
  }

  return null;
};

export default WeeklyJourney;
