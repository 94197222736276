import { makeStyles } from '@material-ui/core';
import React from 'react';
import arrow from '../images/arrow.png';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.dark,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'underline',
    display: 'inline-block',
    padding: 0,
    fontWeight: 'bold',
    fontSize: '1rem',
    textAlign: 'left',
    '&:hover, &:focus': {
      textDecoration: 'none'
    }
  },
  arrow: {
    width: 14,
    height: 14,
    margin: '0 0 0 5px',
    padding: '3px 0 0 0'
  }
}));

const Link = (props: {
  text: string;
  href: string;
  target?: string;
  hasArrow?: boolean;
  linkProps?: JSX.IntrinsicAttributes &
    React.ClassAttributes<HTMLAnchorElement> &
    React.AnchorHTMLAttributes<HTMLAnchorElement>;
}) => {
  const classes = useStyles();

  return (
    <a
      {...props.linkProps}
      className={classes.link}
      href={props.href}
      data-testid="link"
      target={!props.target ? '_self' : props.target}
    >
      {props.text}
      {props.hasArrow && (
        <img className={classes.arrow} src={arrow} alt="link arrow"></img>
      )}
    </a>
  );
};

export default Link;
