import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  linkButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'underline',
    display: 'inline',
    margin: 0,
    padding: 0,
    fontFamily: 'Pantograph Sans Regular, Arial',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    '&:hover, &:focus': {
      textDecoration: 'none'
    }
  }
}));

const LinkButton = (props: {
  text: string;
  onClick: () => void;
  buttonProps?: any;
}) => {
  const classes = useStyles();

  return (
    <button
      {...props.buttonProps}
      className={classes.linkButton}
      onClick={() => props.onClick()}
      role="link"
    >
      {props.text}
    </button>
  );
};

export default LinkButton;
