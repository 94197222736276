import React from 'react';
import { Helmet } from 'react-helmet-async';
/* istanbul ignore file */

const Head = () => {
  const vixIframeScript = process.env.REACT_APP_IFRAME_SCRIPT_URL;
  if (
    !process.env.REACT_APP_MOCK_IFRAME ||
    process.env.REACT_APP_MOCK_IFRAME === 'false'
  ) {
    return (
      <Helmet>
        <script
          type="text/javascript"
          src={vixIframeScript}
          integrity={process.env.REACT_APP_IFRAME_SCRIPT_HASH}
          crossOrigin="anonymous"
        ></script>
      </Helmet>
    );
  }
  return null;
};

export default Head;
