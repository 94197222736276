import React from 'react';
import { makeStyles } from '@material-ui/core';
import arrow from '../images/arrow.svg';

const useStyles = makeStyles((theme) => ({
  backButton: {
    marginRight: 'auto',
    padding: '12px 0 0 0',
    display: 'inline-block',
    cursor: 'pointer'
  }
}));

const BackButton = (props: { onClick: () => void }) => {
  const classes = useStyles();

  return (
    <div
      data-test="backButton"
      className={classes.backButton}
      onClick={props.onClick}
    >
      <input type="image" src={arrow} tabIndex={0} alt="Back"></input>
    </div>
  );
};

export default BackButton;
