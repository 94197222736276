import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import PdfInspection from './PdfInspection';
import { InspectionTap } from '../queries/journeyQuery';

const BORDER_COLOR_JOURNEY_INCOMPLETE = '#ff3f3f';
const BORDER_STYLE = 'solid';

const styles = StyleSheet.create({
  tableJourneyInspection: {
    display: 'flex',
    width: 'auto',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR_JOURNEY_INCOMPLETE,
    borderWidth: 0,
    marginBottom: 10
  }
});

const PdfInspections = (props: { inspections: InspectionTap[] }) => {
  return (
    <View style={styles.tableJourneyInspection}>
      {props.inspections.map(
        (
          inspection,
          index: number
        ) => {
          return <PdfInspection inspection={inspection} key={index} />;
        }
      )}
    </View>
  );
};

export default PdfInspections;
