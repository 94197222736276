import React, { Fragment } from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';
import PdfRefundsHeader from './PdfRefundsHeader';
import PdfRefundRow from './PdfRefundRow';
import { Refund } from '../utils/groupJourneysByDay';

const BORDER_COLOR_REFUND = '#bfbfbf';
const BORDER_STYLE = 'solid';

const styles = StyleSheet.create({
  tableRefund: {
    display: 'flex',
    width: 'auto',
    borderBottom: 1,
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR_REFUND,
    marginBottom: 10
  }
});

const PdfRefunds = (props: { refundTotal: number; refunds: Refund[] }) => {
  if (props.refundTotal > 0) {
    return (
      <Fragment>
        <View data-testid="refund" style={styles.tableRefund}>
          <PdfRefundsHeader refundTotal={props.refundTotal} />
          {props.refunds.map((refund, key) => {
            return <PdfRefundRow refund={refund} key={key} />;
          })}
        </View>
      </Fragment>
    );
  }

  return null;
};

export default PdfRefunds;
