import {
  AppBar,
  Button,
  CardMedia,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
  Toolbar
  } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import ContactlessLink from './containers/ContactlessLink';
import logo from './images/logo.svg';
import Routes from './Routes';

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: 'none'
  },
  root: {
    flexGrow: 1
  },
  title: {
    flexGrow: 1,
    textTransform: 'none',
    paddingLeft: 10,
    fontSize: 18
  },
  menuButton: {
    textTransform: 'none'
  },
  clearButton: {
    textTransform: 'none',
    marginTop: '30px',
    fontWeight: 'bold'
  },
  inputButton: {
    textTransform: 'none',
    marginTop: '30px',
    fontWeight: 'bold'
  },
  media: {
    height: 80,
    width: 180
  },
  container: {
    paddingTop: 110,
    width: '100%',
    maxWidth: '450px',
    margin: 'auto'
  },
  dialogButtons: {
    width: '40%',
    justifyContent: 'left',
    textTransform: 'none',
    borderRadius: '0',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(3)
  }
}));

export interface SessionTimeouts {
  expiryWarningTimeout: NodeJS.Timeout;
  expiryTimeout: NodeJS.Timeout;
}

function App({
  location,
  history
} : RouteComponentProps) {
  console.log();
  const [isAuthorised, setIsAuthorised] = useState(false);
  const [displayTimeoutDialog, setDisplayTimeoutDialog] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [
    sessionTimeouts,
    setSessionTimeouts
  ] = useState<SessionTimeouts | null>(null);
  const [authToken, setAuthToken] = useState('');
  const classes = useStyles();

  useEffect(() => {
    if (
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1'
    ) {
      setIsAuthorised(false);
    }

    if (!authToken && location.pathname !== '/') {
      setIsAuthorised(false);

      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Grid justify="space-between" container>
            <Grid item>
              <Button color="inherit" to={'/'} component={Link}>
                <CardMedia
                  id="logo"
                  className={classes.media}
                  image={logo}
                  title="Transport for Greater Manchester"
                />
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Container component="main">
        <div className={classes.container}>
          <Routes
            appProps={{ isAuthorised }}
            authToken={authToken}
            sessionExpired={sessionExpired}
            setAuthToken={setAuthToken}
            setIsAuthorised={setIsAuthorised}
            setDisplayTimeoutDialog={setDisplayTimeoutDialog}
            setSessionExpired={setSessionExpired}
            sessionTimeouts={sessionTimeouts}
            setSessionTimeouts={setSessionTimeouts}
            displayTimeoutDialog={displayTimeoutDialog}
          />
          <ContactlessLink />
        </div>
      </Container>
      <div>
        <Dialog
          maxWidth="xs"
          open={displayTimeoutDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Your session will soon timeout
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Your session will automatically timeout in{' '}
              {Number(process.env.REACT_APP_SESSION_EXPIRY_MINS || 30) -
                Number(
                  process.env.REACT_APP_SESSION_EXPIRY_WARNING_MINS || 25
                )}{' '}
              minutes for security reasons.
            </DialogContentText>
          </DialogContent>
          <Button
            onClick={() => setDisplayTimeoutDialog(false)}
            className={classes.dialogButtons}
            variant="contained"
            color="primary"
          >
            OK
          </Button>
        </Dialog>
      </div>
    </div>
  );
}

export default withRouter(App);
