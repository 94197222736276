import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import {
  filterCompleteJourneys,
  getDescriptions,
  getIncompleteJourneys,
  getUniqueDescriptions,
  isIncompleteJourney
} from '../libs/utils';
import { calculateAllTotals } from '../utils/calculateAllTotals';
import { penceToPound } from '../utils/penceToPound';
import { groupJourneysByFareAndOrder } from '../utils/groupJourneysByFare';
import { JourneyFareGroup } from '../utils/groupJourneysByDay';
import { Journey, InspectionTap } from '../queries/journeyQuery';

const BORDER_COLOR_DATE = '#939393';
const BORDER_STYLE = 'solid';
const COL1_WIDTH = 35;
const COL2_WIDTH = 55;
const COL3_WIDTH = 10;
const COLOR_REFUND = '#0000ff';
const COLOR_INSPECTION = '#d00505';
const COLOR_INCOMPLETE = '#d00505';

const styles = StyleSheet.create({
  tableDate: {
    display: 'flex',
    width: 'auto',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR_DATE,
    borderWidth: 1,
    marginBottom: 10
  },
  tableRowDate: {
    margin: 'auto',
    flexDirection: 'row',
    backgroundColor: '#f6f6f5'
  },
  tableCol1Header: {
    width: COL1_WIDTH + '%'
  },
  tableCol2Header: {
    width: COL2_WIDTH + '%'
  },
  tableCol3Header: {
    width: COL3_WIDTH + '%',
    textAlign: 'right'
  },
  tableCellHeader: {
    margin: 10,
    fontSize: 10,
    fontWeight: 600
  },
  tableCellHeaderZones: {
    margin: 10,
    fontSize: 9,
    fontWeight: 1000
  },
  inspection: {
    color: COLOR_INSPECTION
  },
  refund: {
    color: COLOR_REFUND
  },
  incomplete: {
    color: COLOR_INCOMPLETE
  },
  vertical: {
    color: '#000000'
  }
});

const generateFareDescriptions = (journeys: JourneyFareGroup[], index: number) => {
  const descriptions = getDescriptions(journeys);
  const completeJourneys = filterCompleteJourneys(descriptions);
  const incompleteJourneys = getIncompleteJourneys(
    descriptions,
    isIncompleteJourney
  );

  const uniqueDescriptions = getUniqueDescriptions(completeJourneys);

  const fareDescriptions = uniqueDescriptions.map(
    (description, idx: number) => {
      return uniqueDescriptions.length === 1 ||
        idx === uniqueDescriptions.length - 1 ? (
        <Text key={idx}>{description} </Text>
      ) : (
        <Text key={idx}>{description} | </Text>
      );
    }
  );

  if (incompleteJourneys.length > 0) {
    if (fareDescriptions.length > 0) {
      fareDescriptions.push(
        <Text key={index + 1} style={styles.vertical}>
          |{' '}
        </Text>
      );
    }

    fareDescriptions.push(
      <Text key={`incompleteJourney${index + 1}`} style={styles.incomplete}>
        Incomplete journey ({incompleteJourneys.length}){' '}
      </Text>
    );
  }

  return fareDescriptions;
};

const generateInspectionDescriptions = (inspections: InspectionTap[]) => {
  // filter out inspections with a finalOutstandingBalance === 0
  const filteredInspections = inspections.filter(
    (inspection) => inspection.finalOutstandingBalance > 0
  );

  if (filteredInspections.length !== 0) {
    return filteredInspections.length > 1 ? (
      <Text style={styles.inspection}>
        <Text style={styles.vertical}> | </Text>Standard fare (
        {filteredInspections.length}){' '}
      </Text>
    ) : (
      <Text style={styles.inspection}>
        <Text style={styles.vertical}> | </Text>Standard fare{' '}
      </Text>
    );
  }
};

const PdfDateHeader = (props: {
  data: {
    date: React.ReactNode;
    journeys: Journey[];
    inspections: InspectionTap[];
    inspection: InspectionTap;
    refundTotal: number;
  };
  index: number;
}) => {
  return (
    <View style={styles.tableDate}>
      <View style={styles.tableRowDate}>
        <View style={styles.tableCol1Header}>
          <Text data-testid="date" style={styles.tableCellHeader}>
            {props.data.date}
          </Text>
        </View>
        <View style={styles.tableCol2Header}>
          <Text
            data-testid="fareDescriptions"
            style={styles.tableCellHeaderZones}
          >
            {props.data.journeys &&
              generateFareDescriptions(
                groupJourneysByFareAndOrder(props.data.journeys),
                props.index
              )}
            {props.data.inspections &&
              generateInspectionDescriptions(props.data.inspections)}
            {props.data.inspection && (
              <Text style={styles.inspection}>Standard fare</Text>
            )}
            {props.data.refundTotal > 0 && (
              <Text style={styles.refund} data-testid="refundDescriptions">
                <Text style={styles.vertical}>| </Text>Refunds issued
              </Text>
            )}
          </Text>
        </View>
        <View style={styles.tableCol3Header}>
          <Text data-testid="fareTotal" style={styles.tableCellHeader}>
            {`£${penceToPound(calculateAllTotals(props.data))}`}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default PdfDateHeader;
