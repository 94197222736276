import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { penceToPound } from '../utils/penceToPound';
import { calculateJourneyTotals } from '../utils/journeyTotal';

import missingJourney from '../images/missing-journey.png';

const BORDER_COLOR_JOURNEY_INCOMPLETE = '#ff3f3f';
const COL1_WIDTH = 4;
const COL2_WIDTH = 56;
const COL3_WIDTH = 30;
const COL4_WIDTH = 10;

const styles = StyleSheet.create({
  tableRowJourney: {
    margin: 'auto',
    flexDirection: 'row',
    backgroundColor: '#fff',
    borderWidth: 1,
    borderLeft: 1,
    borderRight: 1,
    borderTop: 1,
    borderColor: BORDER_COLOR_JOURNEY_INCOMPLETE
  },
  tableCol1Header: {
    width: COL1_WIDTH + '%'
  },
  tableCol2Header: {
    width: COL2_WIDTH + '%'
  },
  tableCol3Header: {
    width: COL3_WIDTH + '%'
  },
  tableCol4Header: {
    width: COL4_WIDTH + '%',
    textAlign: 'right'
  },
  tableCell: {
    margin: 10,
    fontSize: 10
  },
  image: {
    height: 12,
    width: 12
  }
});

const PdfIncompleteJourneyHeader = (props: {
  journeys: any;
  refund: boolean;
}) => {
  return (
    <View style={styles.tableRowJourney}>
      <View style={styles.tableCol1Header}>
        <Text style={styles.tableCell}>
          <Image style={styles.image} src={missingJourney} />
        </Text>
      </View>
      <View style={styles.tableCol2Header}>
        <Text style={styles.tableCell}>
          <Text data-testid="missingTouchsTitle">Incomplete journeys</Text>
        </Text>
      </View>
      <View style={styles.tableCol3Header}>
        <Text style={styles.tableCell}></Text>
      </View>
      <View style={styles.tableCol4Header}>
        <Text data-testid="missingTouchsTotal" style={styles.tableCell}>
          {`£${penceToPound(
            calculateJourneyTotals(props.journeys).toString()
          )}`}
          {props.refund && '*'}
        </Text>
      </View>
    </View>
  );
};

export default PdfIncompleteJourneyHeader;
