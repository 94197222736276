import React, { Fragment } from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';
import PdfJourneyHeader from './PdfJourneyHeader';
import PdfIncompleteJourneyHeader from './PdfIncompleteJourneyHeader';
import PdfJourneyRow from './PdfJourneyRow';
import fareDescription from '../libs/fareDescription';
import { Journey } from '../queries/journeyQuery';

const BORDER_COLOR_JOURNEY = '#bfbfbf';
const BORDER_STYLE = 'solid';

const styles = StyleSheet.create({
  tableJourney: {
    display: 'flex',
    width: 'auto',
    borderTop: 1,
    borderBottom: 1,
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR_JOURNEY,
    marginBottom: 10
  },
  tableJourneyIncomplete: {
    display: 'flex',
    width: 'auto',
    borderBottom: 1,
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR_JOURNEY,
    marginBottom: 10
  }
});

const isIncompleteJourney = (journey: Journey) => {
  const incomplete = '-D';
  const matchingLocations = journey.tapOn.location === journey.tapOff.location;

  return matchingLocations && journey.fareDescription.includes(incomplete);
};

const PdfJourney = (props: { title: string; journeys: Journey[] }) => {
  if (props.journeys) {
    const completedJourneys = props.journeys.filter(
      (journey) =>
        journey.tapOff.location !== null && !isIncompleteJourney(journey)
    );
    const incompleteJourney = props.journeys.filter(
      (journey) =>
        journey.tapOff.location === null || isIncompleteJourney(journey)
    );

    return (
      <Fragment>
        {completedJourneys.length > 0 && (
          <View data-testid="completedJourney" style={styles.tableJourney}>
            <PdfJourneyHeader
              title={props.title}
              journeys={completedJourneys}
              refund={completedJourneys.some((journey) => journey.refund)}
            />
            {completedJourneys.map((journey, key) => {
              return (
                <PdfJourneyRow
                  journey={journey}
                  key={key}
                  isSingleFare={fareDescription('SF') === props.title}
                  isResidual={fareDescription('1-R') === props.title}
                />
              );
            })}
          </View>
        )}
        {incompleteJourney.length > 0 && (
          <View
            data-testid="incompleteJourney"
            style={styles.tableJourneyIncomplete}
          >
            <PdfIncompleteJourneyHeader
              journeys={incompleteJourney}
              refund={incompleteJourney.some((journey) => journey.refund)}
            />
            {incompleteJourney.map((journey, key) => {
              return (
                <PdfJourneyRow
                  journey={journey}
                  key={key}
                  isSingleFare={false}
                  isResidual={false}
                />
              );
            })}
          </View>
        )}
      </Fragment>
    );
  }

  return null;
};

export default PdfJourney;
