import { makeStyles } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import { withStyles } from '@material-ui/core/styles';
import Add from '@material-ui/icons/AddCircleOutline';
import Remove from '@material-ui/icons/RemoveCircleOutline';
import React, { Fragment, useState } from 'react';
import WeeklyDateHeader from './WeeklyDateHeader';
import moment from 'moment-timezone';
import { timezone } from '../data';
import WeeklyJourney from './WeeklyJourney';

const ExpansionPanel = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    }
  }
})(MuiAccordion);

const ExpansionPanelSummary = withStyles({
  expanded: {},
  root: {
    backgroundColor: '#fafafa',
    marginBottom: 12,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
      margin: '12px 0'
    }
  },
  content: {
    margin: '12px 0',
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expandIcon: {
    position: 'absolute',
    bottom: 0,
    right: '10px',
    '&$expanded': {
      position: 'absolute',
      bottom: 0,
      right: '10px'
    }
  }
})(MuiAccordionSummary);

const useStyles = makeStyles((theme) => ({
  expansionPanelSummary: {
    paddingLeft: 0,
    paddingRight: 0,
    border: '1px solid #7a7a7a',
    marginBottom: '10px'
  },
  hidden: {
    display: 'none'
  },
  weeklyDate: {
    display: 'block',
    margin: '10px 15px 0 15px',
    borderBottom: '1px solid #e0e0e0',
    paddingBottom: '5px',
    paddingRight: '20px'
  }
}));

const WeeklyExpansionPanelContainer = (props: {
  index: number;
  fareDescription: string;
  weekStartDate: string;
  ticketJourneysByDay: any[];
  setEnquiryJourneys: any;
}) => {
  const classes = useStyles();
  const [expandPanel, setExpandPanel] = useState(false);
  const [toggleIndex, setToggleIndex] = useState();

  const handleToggle = (event: any, index: any, expanded: boolean) => {
    if (toggleIndex !== index) {
      setToggleIndex(index);
    }

    setExpandPanel(expanded ? true : false);
  };

  return (
    <ExpansionPanel
      key={props.index}
      data-test="expansionPanel"
      data-testid="expansionPanel"
      onChange={(event, expanded) => handleToggle(event, props.index, expanded)}
    >
      <ExpansionPanelSummary
        aria-controls={`additional-actions${props.index}-content`}
        id={`additional-actions${props.index}-header`}
        className={classes.expansionPanelSummary}
        expandIcon={
          toggleIndex === props.index && expandPanel ? <Remove /> : <Add />
        }
      >
        <WeeklyDateHeader
          fareDescription={props.fareDescription}
          weekStartDate={props.weekStartDate}
          titleId={`tableTitle-${props.index}`}
          index={props.index}
          ticketJourneys={props.ticketJourneysByDay}
        />
      </ExpansionPanelSummary>
      {props.ticketJourneysByDay.map((dayGroup) => {
        return (
          <Fragment>
            <div className={classes.weeklyDate}>
              <strong>
                {moment(dayGroup[0].tapOn.dateTime)
                  .tz(timezone)
                  .format('dddd Do MMMM')}
              </strong>
            </div>
            {dayGroup.map((dayJourney: any, index: number) => (
              <WeeklyJourney
                key={index}
                journey={dayJourney}
                setEnquiryJourneys={props.setEnquiryJourneys}
              />
            ))}
          </Fragment>
        );
      })}
    </ExpansionPanel>
  );
};

export default WeeklyExpansionPanelContainer;
