import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import {
  filterCompleteJourneys,
  getDescriptions,
  getIncompleteJourneys,
  getUniqueDescriptions,
  isIncompleteJourney
} from '../libs/utils';
import { calculateAllTotals } from '../utils/calculateAllTotals';
import { penceToPound } from '../utils/penceToPound';
import { groupJourneysByFareAndOrder } from '../utils/groupJourneysByFare';
import { Journey, InspectionTap } from '../queries/journeyQuery';
import { JourneyFareGroup } from '../utils/groupJourneysByDay';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    border: 0
  },
  tableContainer: {
    backgroundColor: '#fafafa'
  },
  tableHeader: {
    verticalAlign: 'middle',
    paddingLeft: '8px',
    fontSize: '1.1rem'
  },
  tableCell: {
    padding: '5px 10px 5px 10px',
    borderBottom: 0
  },
  tableCellDayCost: {
    padding: '5px 15px 0 10px',
    borderBottom: 0,
    fontSize: 16
  },
  tableCellFareDescription: {
    padding: '10px 10px 5px 10px',
    borderBottom: 0
  },
  tableCellFareInspection: {
    color: theme.palette.error.main
  },
  tableCellRefund: {
    // color: theme.palette.refunds.main
    color: '#0000ff'
  },
  description: {
    paddingBottom: '5px'
  },
  incompleteJourney: {
    color: theme.palette.error.main,
    paddingBottom: '5px'
  }
}));

const generateFareDescriptions = (
  journeys: JourneyFareGroup[],
  classes: Record<
    | 'root'
    | 'paper'
    | 'tableContainer'
    | 'tableHeader'
    | 'tableCell'
    | 'tableCellDayCost'
    | 'tableCellFareDescription'
    | 'tableCellFareInspection'
    | 'tableCellRefund'
    | 'description'
    | 'incompleteJourney',
    string
  >,
  index: number
) => {
  const descriptions = getDescriptions(journeys);
  const completeJourneys = filterCompleteJourneys(descriptions);
  const incompleteJourneys = getIncompleteJourneys(
    descriptions,
    isIncompleteJourney
  );
  const uniqueDescriptions = getUniqueDescriptions(completeJourneys);

  const fareDescriptions = uniqueDescriptions.map(
    (description: any, idx: number) => {
      return uniqueDescriptions.length === 1 ||
        idx === uniqueDescriptions.length - 1 ? (
        <div key={idx} className={classes.description}>
          {description}
        </div>
      ) : (
        <div key={idx + Math.random()} className={classes.description}>
          {description}{' '}
        </div>
      );
    }
  );

  if (incompleteJourneys.length > 0) {
    fareDescriptions.push(
      <div key={index + Math.random()} className={classes.incompleteJourney}>
        Incomplete journey ({incompleteJourneys.length})
      </div>
    );
  }

  return fareDescriptions;
};

const generateInspectionDescriptions = (inspections: any[]) => {
  // filter out inspections with a finalOutstandingBalance === 0
  const filteredInspections = inspections.filter(
    (inspection: { finalOutstandingBalance: number }) =>
      inspection.finalOutstandingBalance > 0
  );

  if (filteredInspections.length !== 0) {
    return filteredInspections.length > 1 ? (
      <div>Standard fare ({filteredInspections.length})</div>
    ) : (
      <div>Standard fare</div>
    );
  }
};

const DateHeader = (props: {
  data: {
    date: string;
    journeys: Journey[];
    inspections: InspectionTap[];
    inspection: { finalOutstandingBalance: number };
    refundTotal: number;
  };
  titleId: string;
  index: number;
}) => {
  const classes = useStyles();

  return (
    <div id={`description-${props.index}`} className={classes.root}>
      <Paper variant="outlined" square className={classes.paper}>
        <TableContainer>
          <Table
            size="medium"
            className={classes.tableContainer}
            data-test="dateHeader"
          >
            <TableBody>
              <TableRow tabIndex={-1}>
                <TableCell className={classes.tableCellDayCost} align="left">
                  <strong data-test="dateDay">
                    {props.data.date.substr(0, props.data.date.indexOf(' '))}
                  </strong>
                </TableCell>
                <TableCell className={classes.tableCellDayCost} align="right">
                  <strong data-testid="fareTotal" data-test="fareTotal">
                    {`£${penceToPound(
                      calculateAllTotals(props.data)
                    )}`}
                  </strong>
                </TableCell>
              </TableRow>
              <TableRow tabIndex={-1}>
                <TableCell className={classes.tableCell} align="left">
                  <strong data-testid="date" data-test="date">
                    {props.data.date.substr(props.data.date.indexOf(' ') + 1)}
                  </strong>
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align="right"
                ></TableCell>
              </TableRow>
              <TableRow tabIndex={-1}>
                <TableCell
                  className={classes.tableCellFareDescription}
                  align="left"
                  colSpan={2}
                  data-test="fareDescriptions"
                >
                  <strong data-testid="fareDescriptions">
                    {props.data.journeys &&
                      generateFareDescriptions(
                        groupJourneysByFareAndOrder(props.data.journeys),
                        classes,
                        props.index
                      )}
                  </strong>
                  <strong
                    data-test="fareDescriptionsInspections"
                    className={classes.tableCellFareInspection}
                  >
                    {props.data.inspections &&
                      generateInspectionDescriptions(props.data.inspections)}
                    {props.data.inspection &&
                      props.data.inspection.finalOutstandingBalance > 0 && (
                        <div>Standard fare</div>
                      )}
                  </strong>
                  {props.data.refundTotal > 0 && (
                    <strong
                      className={classes.tableCellRefund}
                      data-testid="refundDescriptions"
                    >
                      <div>Refunds issued</div>
                    </strong>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default DateHeader;
