export const calculateAllTotals = (data: any) => {
  let journeyTotals = 0;
  let inspectionTotals = 0;

  data.journeys &&
    data.journeys.forEach((journey: any) => {
      journeyTotals += journey.finalAmount;
    });

  data.inspections &&
    data.inspections.forEach((inspection: any) => {
      inspectionTotals += inspection.finalOutstandingBalance;
    });

  if (data.inspection) {
    const refundTotal = data.refundTotal || 0;

    return (
      journeyTotals +
      inspectionTotals +
      data.inspection.finalOutstandingBalance -
      refundTotal
    );
  }

  return journeyTotals + inspectionTotals - data.refundTotal;
};
