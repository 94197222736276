import React from 'react';
import Link from './Link';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  contactlessLink: {
    textAlign: 'left',
    margin: '20px 0'
  }
}));

const ContactlessLink = () => {
  const classes = useStyles();
  const linkText = 'More about contactless';
  const linkHref = 'https://tfgm.com/tickets-and-passes/contactless';
  const hasArrow = true;

  return (
    <div className={classes.contactlessLink} data-test="contactlessLink">
      <Link
        text={linkText}
        href={linkHref}
        target="_blank"
        hasArrow={hasArrow}
      ></Link>
    </div>
  );
};

export default ContactlessLink;
