import React, { useEffect } from 'react';
import { registerFrameWhisper } from '../utils/vix-iframe';

const Iframe = () => {
  useEffect(() => {
    registerFrameWhisper();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div id="whisper-registration-region" />;
};

export default Iframe;
