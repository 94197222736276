const fareDescription = (code: string) => {
  // if the last 2 characters match '-R' it is a residual value
  if (code && code.slice(-2) === '-R') {
    return 'Non-capped journeys';
  }

  switch (code) {
    // this SF case is a custom grouping created by the UI and doesn't come from the Vix data
    case 'SF':
      return 'Single Fares';
    case '1-SF':
      return 'Zone 1 anytime single';
    case '1-C':
      return 'Zone 1 off-peak travelcard';
    case '1-PC':
      return 'Zone 1 anytime travelcard';
    case '2-SF':
      return 'Zone 2 anytime single';
    case '2-C':
      return 'Zone 2 off-peak travelcard';
    case '2-PC':
      return 'Zone 2 anytime travelcard';
    case '3-SF':
      return 'Zone 3 anytime single';
    case '3-C':
      return 'Zone 3 off-peak travelcard';
    case '3-PC':
      return 'Zone 3 anytime travelcard';
    case '4-SF':
      return 'Zone 4 anytime single';
    case '4-C':
      return 'Zone 4 off-peak travelcard';
    case '4-PC':
      return 'Zone 4 anytime travelcard';
    case '23-SF':
      return 'Zone 2+3 anytime single';
    case '23-C':
      return 'Zone 2+3 off-peak travelcard';
    case '23-PC':
      return 'Zone 2+3 anytime travelcard';
    case '34-SF':
      return 'Zone 3+4 anytime single';
    case '34-C':
      return 'Zone 3+4 off-peak travelcard';
    case '34-PC':
      return 'Zone 3+4 anytime travelcard';
    case '12-SF':
      return 'Zone 1+2 anytime single';
    case '12-C':
      return 'Zone 1+2 off-peak travelcard';
    case '12-PC':
      return 'Zone 1+2 anytime travelcard';
    case '24-SF':
      return 'Zone 2+3+4 anytime single';
    case '24-C':
      return 'Zone 2+3+4 off-peak travelcard';
    case '24-PC':
      return 'Zone 2+3+4 anytime travelcard';
    case '13-SF':
      return 'Zone 1+2+3 anytime single';
    case '13-C':
      return 'Zone 1+2+3 off-peak travelcard';
    case '13-PC':
      return 'Zone 1+2+3 anytime travelcard';
    case '14-SF':
      return 'Zone 1+2+3+4 anytime single';
    case '14-C':
      return 'Zone 1+2+3+4 off-peak travelcard';
    case '14-PC':
      return 'Zone 1+2+3+4 anytime travelcard';
    case '1-WC':
      return 'Zone 1 weekly cap';
    case '2-WC':
      return 'Zone 2 weekly cap';
    case '3-WC':
      return 'Zone 3 weekly cap';
    case '4-WC':
      return 'Zone 4 weekly cap';
    case '12-WC':
      return 'Zone 1+2 weekly cap';
    case '13-WC':
      return 'Zone 1+2+3 weekly cap';
    case '14-WC':
      return 'Zone 1+2+3+4 weekly cap';
    case '23-WC':
      return 'Zone 2+3 weekly cap';
    case '24-WC':
      return 'Zone 2+3+4 weekly cap';
    case '34-WC':
      return 'Zone 3+4 weekly cap';
    // this IJ case is a custom grouping created by the UI and doesn't come from the Vix data
    case 'IJ':
    default:
      return 'Incomplete journey';
  }
};

export default fareDescription;
