import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import React from 'react';
import watch from '../images/apple-watch.png';
import card from '../images/card.png';
import phone from '../images/iphone.png';

const TITLE_COL1_WIDTH = 30;
const TITLE_COL2_WIDTH = 5;
const TITLE_COL3_WIDTH = 25;
const TITLE_COL4_WIDTH = 40;

const styles = StyleSheet.create({
  titleHeader: {
    display: 'flex',
    width: 'auto',
    marginBottom: 20
  },
  titleHeaderRow: {
    margin: 'auto',
    flexDirection: 'row'
  },
  titleCol1: {
    width: TITLE_COL1_WIDTH + '%',
    textAlign: 'left'
  },
  titleCol2: {
    width: TITLE_COL2_WIDTH + '%',
    textAlign: 'right'
  },
  titleCol3: {
    width: TITLE_COL3_WIDTH + '%',
    textAlign: 'left'
  },
  titleCol4: {
    width: TITLE_COL4_WIDTH + '%',
    textAlign: 'right'
  },
  titleCell: {
    fontSize: 12,
    fontWeight: 'bold'
  },
  formFactorDefaultImage: {
    height: 15,
    width: 10
  },
  formFactorCardImage: {
    height: 20,
    width: 20
  }
});

const statementDateRange = (range: string) => {
  const [statementDateStart, statmentDateEnd] = range.split('_');

  return `${moment(statementDateStart).format('D MMMM YYYY')} to ${moment(
    statmentDateEnd
  ).format('D MMMM YYYY')}`;
};

const getHeaderImage = (formFactor: string) => {
  switch (formFactor) {
    case 'watch':
      return watch;
    case 'mobile phone':
      return phone;
    case 'mini card':
      return card;
    default:
      return card;
  }
};

const PdfTitleHeader = (props: {
  lastFourDigits: string;
  dateRange: string;
  formFactor: string;
}) => {
  return (
    <View style={styles.titleHeader}>
      <View style={styles.titleHeaderRow}>
        <View style={styles.titleCol1}>
          <Text data-testid="title" style={styles.titleCell}>
            Contactless statement
          </Text>
        </View>
        <View style={styles.titleCol2}>
          <Image
            style={
              props.formFactor === 'card' || props.formFactor === 'mini card'
                ? styles.formFactorCardImage
                : styles.formFactorDefaultImage
            }
            src={getHeaderImage(props.formFactor)}
          />
        </View>
        <View style={styles.titleCol3}>
          <Text data-testid="lastFourDigits" style={styles.titleCell}>
            Card ending - {props.lastFourDigits}
          </Text>
        </View>
        <View style={styles.titleCol4}>
          <Text data-testid="dateRange" style={styles.titleCell}>
            {statementDateRange(props.dateRange)}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default PdfTitleHeader;
