import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { convertToBST } from '../utils/dateTimeFormat';
import arrow from '../images/arrow.png';
import { Journey } from '../queries/journeyQuery';

const MISSED_TOUCH_TEXT = '#d00505';
const BORDER_COLOR_JOURNEY = '#bfbfbf';
const BORDER_STYLE = 'solid';
const TIME = '#858585';
const COL1_WIDTH = 25;
const COL2_WIDTH = 5;
const COL3_WIDTH = 70;

const styles = StyleSheet.create({
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
    backgroundColor: '#fff',
    borderLeft: 1,
    borderRight: 1,
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR_JOURNEY
  },
  tableCol1: {
    width: COL1_WIDTH + '%'
  },
  tableCol2: {
    width: COL2_WIDTH + '%',
    alignContent: 'flex-start'
  },
  tableCol3: {
    width: COL3_WIDTH + '%'
  },
  tableCellStation: {
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 0,
    fontSize: 10
  },
  tableCellTime: {
    marginTop: 0,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
    fontSize: 10
  },
  missing: {
    color: MISSED_TOUCH_TEXT
  },
  time: {
    color: TIME
  },
  image: {
    height: 7,
    width: 7
  }
});

const PdfJourneyRow = (props: {
  journey: Journey;
  isSingleFare: boolean;
  isResidual: boolean;
}) => {
  return (
    <View>
      <View style={styles.tableRow}>
        <View style={styles.tableCol1}>
          <Text data-testid="tapOnLocation" style={styles.tableCellStation}>
            {props.journey.tapOn.location ? (
              <>
                {props.isSingleFare || props.isResidual
                  ? `${props.journey.tapOn.location} (Z${props.journey.tapOn.zone})`
                  : props.journey.tapOn.location}
              </>
            ) : (
              <Text style={styles.missing}>Missing touch</Text>
            )}
          </Text>
        </View>
        <View style={styles.tableCol2}>
          <Text style={styles.tableCellStation}>
            <Image style={styles.image} src={arrow} />
          </Text>
        </View>
        <View style={styles.tableCol3}>
          <Text data-testid="tapOffLocation" style={styles.tableCellStation}>
            {props.journey.tapOff.location ? (
              <>
                {props.isSingleFare || props.isResidual
                  ? `${props.journey.tapOff.location} (Z${props.journey.tapOff.zone})`
                  : props.journey.tapOff.location}
              </>
            ) : (
              <Text style={styles.missing}>Missing touch</Text>
            )}
          </Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={styles.tableCol1}>
          <Text style={styles.tableCellTime}>
            <Text data-testid="tapOnDateTime" style={styles.time}>
              {props.journey.tapOn.location &&
                convertToBST(props.journey.tapOn.dateTime).format('HH:mm')}
            </Text>
          </Text>
        </View>
        <View style={styles.tableCol2}>
          <Text style={styles.tableCellTime}></Text>
        </View>
        <View style={styles.tableCol3}>
          <Text style={styles.tableCellTime}>
            <Text data-testid="tapOffDateTime" style={styles.time}>
              {props.journey.tapOff.location &&
                convertToBST(props.journey.tapOff.dateTime).format('HH:mm')}
            </Text>
          </Text>
        </View>
      </View>
    </View>
  );
};

export default PdfJourneyRow;
