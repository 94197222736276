import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { convertToBST } from '../utils/dateTimeFormat';
import { penceToPound } from '../utils/penceToPound';
import { Refund } from '../utils/groupJourneysByDay';

const BORDER_COLOR_JOURNEY = '#bfbfbf';
const BORDER_STYLE = 'solid';
const DATE = '#858585';
const COL1_WIDTH = 100;

const styles = StyleSheet.create({
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
    backgroundColor: '#fff',
    borderLeft: 1,
    borderRight: 1,
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR_JOURNEY
  },
  tableCol1: {
    width: COL1_WIDTH + '%'
  },
  tableCellStation: {
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 0,
    fontSize: 10
  },
  tableCellTime: {
    marginTop: 0,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
    fontSize: 10
  },
  date: {
    color: DATE
  }
});

const PdfRefundRow = (props: { refund: Refund }) => {
  return (
    <View>
      <View style={styles.tableRow}>
        <View style={styles.tableCol1}>
          <Text data-testid="refundAmount" style={styles.tableCellStation}>
            {`£${penceToPound(
              Math.abs(props.refund.refundTotal)
            )} refunded`}
          </Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={styles.tableCol1}>
          <Text style={styles.tableCellTime}>
            <Text data-testid="refundDate" style={styles.date}>
              {props.refund.dateTime &&
                convertToBST(props.refund.dateTime).format('D MMMM YYYY')}
            </Text>
          </Text>
        </View>
      </View>
    </View>
  );
};

export default PdfRefundRow;
