import React from 'react';
import moment from 'moment';
import card from '../images/card.svg';
import watch from '../images/apple-watch.svg';
import calendar from '../images/calendar.svg';
import phone from '../images/iphone.svg';
import cardPng from '../images/card.png';
import watchPng from '../images/apple-watch.png';
import calendarPng from '../images/calendar.png';
import phonePng from '../images/iphone.png';
import { makeStyles } from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    textAlign: 'left'
  },
  tableBody: {},
  tableCell: {
    paddingRight: 0,
    paddingBottom: 5,
    paddingLeft: 0,
    border: 0,
    paddingTop: 5,
    verticalAlign: 'middle',
    textAlign: 'left'
  },
  tableCellDateSearch: {
    paddingRight: 0,
    paddingBottom: 20,
    paddingLeft: 0,
    border: 0,
    paddingTop: 5,
    verticalAlign: 'middle',
    textAlign: 'left'
  },
  formFactorDefaultImage: {
    height: 25,
    width: 18,
    paddingLeft: 0,
    verticalAlign: 'middle'
  },
  formFactorCardImage: {
    height: 25,
    width: 25,
    paddingLeft: 0,
    verticalAlign: 'middle'
  }
}));

const statementDateRange = (range: string) => {
  const [statementDateStart, statmentDateEnd] = range.split('_');

  return `${moment(statementDateStart).format('D MMMM YYYY')} to ${moment(
    statmentDateEnd
  ).format('D MMMM YYYY')}`;
};

const getHeaderImage = (formFactor: string, type: string) => {
  switch (formFactor) {
    case 'watch':
      return type === 'svg' ? watch : watchPng;
    case 'mobile phone':
      return type === 'svg' ? phone : phonePng;
    case 'mini card':
      return type === 'svg' ? card : cardPng;
    default:
      return card;
  }
};

const JourneyTitleHeader = (props: {
  lastFourDigits: string;
  dateRange: string;
  formFactor: string;
}) => {
  const classes = useStyles();

  return (
    <Table className={classes.tableContainer}>
      <TableBody className={classes.tableBody}>
        <TableRow>
          <TableCell className={classes.tableCell} align="left" colSpan={1}>
            <img
              className={`
                ${
                  props.formFactor === 'card' ||
                  props.formFactor === 'mini card'
                    ? classes.formFactorCardImage
                    : classes.formFactorDefaultImage
                } ieCardEnding`}
              src={getHeaderImage(props.formFactor, 'svg')}
              alt="payment method"
            />
            <object
              className={`
                ${
                  props.formFactor === 'card' ||
                  props.formFactor === 'mini card'
                    ? classes.formFactorCardImage
                    : classes.formFactorDefaultImage
                } cardEnding`}
              type="image/svg+xml"
              data={getHeaderImage(props.formFactor, 'svg')}
              tabIndex={-1}
              aria-label="Card"
            >
              <img
                className={
                  props.formFactor === 'card' ||
                  props.formFactor === 'mini card'
                    ? classes.formFactorCardImage
                    : classes.formFactorDefaultImage
                }
                src={getHeaderImage(props.formFactor, 'png')}
                alt="payment method"
              />
            </object>
          </TableCell>
          <TableCell
            className={classes.tableCell}
            align="left"
            data-testid="lastFourDigits"
          >
            <strong>Card ending - {props.lastFourDigits}</strong>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            className={classes.tableCellDateSearch}
            align="left"
            colSpan={1}
          >
            <img
              className={`${classes.formFactorCardImage} ieCal`}
              src={calendar}
              alt="calendar"
            />
            <object
              className={`${classes.formFactorCardImage} cal`}
              type="image/svg+xml"
              data={calendar}
              tabIndex={-1}
              aria-label="Calendar"
            >
              <img
                className={classes.formFactorCardImage}
                src={calendarPng}
                alt="calendar"
              />
            </object>
          </TableCell>
          <TableCell
            className={classes.tableCellDateSearch}
            align="right"
            colSpan={2}
          >
            <strong data-testid="dateRange">
              {statementDateRange(props.dateRange)}
            </strong>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default JourneyTitleHeader;
