import React from 'react';
import { InspectionTap } from '../queries/journeyQuery';
import { penceToPound } from '../utils/penceToPound';
import { makeStyles } from '@material-ui/core';
import { convertToBST } from '../utils/dateTimeFormat';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import StandardFareLink from './StandardFareLink';
import inspections from '../images/inspections.svg';
import inspectionsPng from '../images/inspections.png';

const useStyles = makeStyles((theme) => ({
  inspectionIcon: {
    height: 20,
    width: 20
  },
  tableHeaderImage: {
    verticalAlign: 'middle',
    width: '10%',
    paddingRight: 0,
    borderTop: `1px solid ${theme.palette.error.main}`,
    borderLeft: `1px solid ${theme.palette.error.main}`,
    borderBottom: `1px solid ${theme.palette.error.main}`
  },
  tableHeaderJourney: {
    borderTop: `1px solid ${theme.palette.error.main}`,
    borderBottom: `1px solid ${theme.palette.error.main}`,
    paddingLeft: 0
  },
  tableHeaderTotal: {
    borderTop: `1px solid ${theme.palette.error.main}`,
    borderRight: `1px solid ${theme.palette.error.main}`,
    borderBottom: `1px solid ${theme.palette.error.main}`
  }
}));

const Inspection = (props: {
  inspection: InspectionTap;
}) => {
  const classes = useStyles();

  if (props.inspection.finalOutstandingBalance > 0) {
    return (
      <TableRow data-testid="inspectionItem">
        <TableCell
          className={classes.tableHeaderImage}
          align="left"
          colSpan={1}
          data-testid="inspectionImage"
        >
          <img
            className={`${classes.inspectionIcon} ieInspections`}
            src={inspections}
            alt="inspection icon"
          />
          <object
            className={`${classes.inspectionIcon} inspections`}
            type="image/svg+xml"
            data={inspections}
            tabIndex={-1}
            aria-label="Inspections"
          >
            <img
              className={classes.inspectionIcon}
              src={inspectionsPng}
              alt="inspection icon"
            />
          </object>
        </TableCell>
        <TableCell className={classes.tableHeaderJourney} align="left">
          <strong>Standard fare</strong>{' '}
          {`${convertToBST(props.inspection.dateTime).format('HH:mm')}`}
        </TableCell>
        <TableCell
          className={classes.tableHeaderJourney}
          align="left"
          data-testid="inspectionLink"
        >
          Problem with this charge? Go{' '}
          <StandardFareLink
            text="here"
            href="https://tfgm.com/tickets-and-passes/contactless/standard-fare-appeal"
            target="_blank"
          ></StandardFareLink>{' '}
          to query it.
        </TableCell>
        <TableCell
          className={classes.tableHeaderTotal}
          align="right"
          colSpan={2}
        >
          <strong data-testid="inspectionBalance">
            {`£${penceToPound(props.inspection.finalOutstandingBalance)}`}
          </strong>
        </TableCell>
      </TableRow>
    );
  }

  return null;
};

export default Inspection;
