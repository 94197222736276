import { makeStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { Refund } from '../utils/groupJourneysByDay';
import { convertToBST } from '../utils/dateTimeFormat';
import { penceToPound } from '../utils/penceToPound';

const useStyles = makeStyles((theme) => ({
  tableRefund: {
    marginBottom: 10
  },
  tableBody: {
    '& td': {
      borderBottom: '0px'
    },
    '& tr:last-child': {
      '& td': {
        borderBottom: `1px solid ${theme.palette.grey[300]}`
      }
    },
    '& tr': {
      '& td:first-child': {
        borderLeft: `1px solid ${theme.palette.grey[300]}`
      },
      '& td:last-child': {
        borderRight: `1px solid ${theme.palette.grey[300]}`
      }
    }
  },
  tableHeaderRow: {
    borderTop: `1px solid #0000ff`,
    borderBottom: `1px solid #0000ff`,
    backgroundColor: theme.palette.common.white
  },
  refundRow: {
    backgroundColor: theme.palette.common.white
  },
  tableHeaderRefund: {
    borderLeft: `1px solid #0000ff !important`,
    verticalAlign: 'middle',
    paddingLeft: 16
  },
  tableHeader: {
    borderRight: `1px solid #0000ff !important`,
    verticalAlign: 'middle'
  }
}));

const Refunds = (props: { refundTotal: number; refunds: Refund[] }) => {
  const classes = useStyles();

  if (props.refundTotal > 0)
    return (
      <TableContainer className={classes.tableRefund} data-testid="refundTable">
        <Table size="medium">
          <TableBody className={classes.tableBody}>
            <TableRow className={classes.tableHeaderRow}>
              <TableCell className={classes.tableHeaderRefund} align="left">
                <strong>Refunds issued</strong>
              </TableCell>
              <TableCell className={classes.tableHeader} align="right">
                <strong data-testid="refundTotal">{`+£${penceToPound(
                  props.refundTotal
                )}*`}</strong>
              </TableCell>
            </TableRow>
            {props.refunds.map((refund, index) => {
              return (
                <TableRow
                  key={index}
                  tabIndex={-1}
                  className={classes.refundRow}
                  data-testid="refundRow"
                >
                  <TableCell align="left" colSpan={2}>
                    <strong data-testid="refundAmount">
                      {`£${penceToPound(
                        Math.abs(refund.refundTotal)
                      )} refunded`}
                    </strong>
                    <br />
                    <span data-testid="refundDate">
                      {convertToBST(refund.dateTime).format('D MMMM YYYY')}
                    </span>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );

  return null;
};

export default Refunds;
