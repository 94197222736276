import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import moment from 'moment-timezone';
import { get7DayDateTo } from '../utils/get7DayDate';
import { timezone } from '../data';
import { penceToPound } from '../utils/penceToPound';
import { Journey } from '../queries/journeyQuery';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    border: 0
  },
  tableContainer: {
    backgroundColor: '#fafafa'
  },
  tableHeader: {
    verticalAlign: 'middle',
    paddingLeft: '8px',
    fontSize: '1.1rem'
  },
  tableCell: {
    padding: '5px 10px 5px 10px',
    borderBottom: 0
  },
  tableCellWeeklyCost: {
    padding: '5px 15px 0 10px',
    borderBottom: 0,
    fontSize: 16
  },
  tableCellFareDescription: {
    padding: '10px 10px 5px 10px',
    borderBottom: 0
  },
  tableCellFareInspection: {
    color: theme.palette.error.main
  },
  tableCellRefund: {
    color: '#0000ff'
  },
  description: {
    paddingBottom: '5px'
  },
  incompleteJourney: {
    color: theme.palette.error.main,
    paddingBottom: '5px'
  }
}));

const getWeeklyTotal = (ticketJourneysByDay: any[]) => {
  let weeklyTotal = 0;
  ticketJourneysByDay.forEach((ticketJourney) => {
    ticketJourney.forEach((journey: Journey) => {
      weeklyTotal += journey.finalAmount;
    });
  });

  return weeklyTotal;
};

const WeeklyDateHeader = (props: {
  fareDescription: string;
  weekStartDate: string;
  titleId: string;
  index: number;
  ticketJourneys: Journey[];
}) => {
  const classes = useStyles();

  let weekStart = get7DayDateTo(props.weekStartDate);

  return (
    <div id={`description-${props.index}`} className={classes.root}>
      <Paper variant="outlined" square className={classes.paper}>
        <TableContainer>
          <Table
            size="medium"
            className={classes.tableContainer}
            data-test="dateHeader"
          >
            <TableBody>
              <TableRow tabIndex={-1}>
                <TableCell className={classes.tableCellWeeklyCost} align="left">
                  <strong data-test="dateDay" data-testid="fareDescription">
                    {props.fareDescription}
                  </strong>
                </TableCell>
                <TableCell
                  className={classes.tableCellWeeklyCost}
                  align="right"
                >
                  <strong
                    data-test="weeklyTotal"
                    data-testid="weeklyFareTotal"
                  >{`£${penceToPound(
                    getWeeklyTotal(props.ticketJourneys)
                  )}`}</strong>
                </TableCell>
              </TableRow>
              <TableRow tabIndex={-1}>
                <TableCell className={classes.tableCell} align="left">
                  <div data-testid="dateRange" data-test="date">
                    {moment(props.weekStartDate)
                      .tz(timezone)
                      .format('Do MMMM YYYY')}{' '}
                    to{' '}
                    {moment(weekStart!)
                      .tz(timezone)
                      .format('Do MMMM YYYY')}
                  </div>
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  align="right"
                ></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default WeeklyDateHeader;
