import { makeStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import React, { Fragment } from 'react';
import EnquiryLinkRow from './EnquiryLinkRow';
import { EnquiryFormData } from './EnquiryForm';
import IncompleteJourneyHeader from './IncompleteJourneyHeader';
import JourneyHeader from './JourneyHeader';
import JourneyRow from './JourneyRow';
import { ChangeHandler } from '../libs/hooksLib';
import fareDescription from '../libs/fareDescription';
import { Journey } from '../queries/journeyQuery';

const useStyles = makeStyles((theme) => ({
  tableJourney: {
    marginBottom: 10
  },
  tableBody: {
    '& td': {
      borderBottom: '0px'
    },
    '& tr:last-child': {
      '& td': {
        borderBottom: '1px solid #e0e0e0'
      }
    },
    '& tr': {
      '& td:first-child': {
        borderLeft: '1px solid #e0e0e0'
      },
      '& td:last-child': {
        borderRight: '1px solid #e0e0e0'
      }
    }
  }
}));

const isIncompleteJourney = (journey: any) => {
  const incomplete = '-D';
  const matchingLocations = journey.tapOn.location === journey.tapOff.location;

  return matchingLocations && journey.fareDescription.includes(incomplete);
};

interface JourneyProps {
  data: any;
  journeys: any;
  setEnquiryJourneys?: any;
  title: string;
  enquiryProps?: {
    formData: EnquiryFormData;
    handleChange: ChangeHandler<EnquiryFormData>;
  };
}

const JourneyDetails = ({
  title,
  data,
  journeys,
  enquiryProps,
  setEnquiryJourneys
}: JourneyProps) => {
  const classes = useStyles();

  if (journeys) {
    const completedJourneys = journeys.filter(
      (journey: Journey) =>
        journey.tapOff.location !== null && !isIncompleteJourney(journey)
    );
    const incompleteJourney = journeys.filter(
      (journey: Journey) =>
        journey.tapOff.location === null || isIncompleteJourney(journey)
    );

    return (
      <Fragment>
        {completedJourneys.length > 0 && (
          <TableContainer
            data-testid="completedJourney"
            className={classes.tableJourney}
            data-test="completedJourney"
          >
            <JourneyHeader
              title={title}
              headerColSpan={enquiryProps ? 3 : 2}
              journeys={completedJourneys}
              refund={completedJourneys.some(
                (journey: Journey) => journey.refund
              )}
            />
            <Table size="medium">
              <TableBody className={classes.tableBody}>
                {completedJourneys.map((journey: any, key: number) => {
                  return (
                    <JourneyRow
                      enquiryProps={enquiryProps}
                      journey={journey}
                      key={key}
                      isSingleFare={fareDescription('SF') === title}
                      isResidual={fareDescription('1-R') === title}
                    />
                  );
                })}
                {!enquiryProps && (
                  <EnquiryLinkRow
                    setEnquiryJourneys={() =>
                      setEnquiryJourneys({
                        ...data,
                        title: title,
                        journeys: completedJourneys
                      })
                    }
                  />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {incompleteJourney.length > 0 && (
          <TableContainer
            data-testid="incompleteJourney"
            className={classes.tableJourney}
            data-test="incompleteJourney"
          >
            <Table size="medium">
              <TableBody className={classes.tableBody}>
                <IncompleteJourneyHeader
                  headerColSpan={enquiryProps ? 3 : 2}
                  journeys={incompleteJourney}
                  refund={incompleteJourney.some(
                    (journey: Journey) => journey.refund
                  )}
                />
                {incompleteJourney.map((journey: any, key: any) => {
                  return (
                    <JourneyRow
                      enquiryProps={enquiryProps}
                      journey={journey}
                      key={key}
                      isSingleFare={false}
                      isResidual={false}
                    />
                  );
                })}
                {!enquiryProps && (
                  <EnquiryLinkRow
                    setEnquiryJourneys={() =>
                      setEnquiryJourneys({
                        ...data,
                        title: title,
                        journeys: incompleteJourney
                      })
                    }
                  />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Fragment>
    );
  }

  return null;
};

export default JourneyDetails;
