import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import logo from '../images/tfgm-logo.png';

const moment = require('moment-timezone');

const styles = StyleSheet.create({
  header: {
    display: 'flex',
    flexDirection: 'row',
    width: 'auto',
    height: 100,
    backgroundColor: '#272725',
    marginBottom: 20,
    justifyContent: 'flex-end',
  },
  headerText: {
    alignSelf: 'center',
    fontSize: 10,
    color: 'white',
    marginRight: 10,
    width: 85,
  },
  logoImage: {
    height: 70,
    width: 200,
    alignSelf: 'center',
    marginRight: 'auto',
    marginLeft: 10,
  },
});

const PdfHeader = () => {
  return (
    <View style={styles.header}>
      <Image style={styles.logoImage} src={logo} />
      <Text data-testid="headerDate" style={styles.headerText}>
        Statement created{' '}
        {moment(new Date())
          .tz('Europe/London')
          .format('D MMMM YYYY')}
      </Text>
    </View>
  );
};

export default PdfHeader;
