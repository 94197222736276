import { makeStyles } from '@material-ui/core';
import React from 'react';
import BackButton from './BackButton';

const useStyles = makeStyles((theme) => ({
  navHeader: {
    height: 40,
    width: '100%',
    display: 'flex'
  }
}));

interface NavHeaderProps {
  backButton?: () => void;
}

const NavHeader = (props: NavHeaderProps) => {
  const classes = useStyles();

  return (
    <div className={classes.navHeader}>
      {props.backButton && <BackButton onClick={props.backButton} />}
    </div>
  );
};

export default NavHeader;
