import {
  Button,
  FormControl,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputLabel,
  makeStyles,
  NativeSelect,
  OutlinedInput
} from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import { Alert } from '@material-ui/lab';
import { AlertTitle } from '@material-ui/lab';
import { validate } from 'email-validator';
import React, { useState } from 'react';
import NavHeader from './NavHeader';
import arrow from '../images/white-arrow.png';
import { GroupedJourneys } from '../utils/groupJourneysByDay';
import { useForm } from '../libs/hooksLib';
import { Journey } from '../queries/journeyQuery';
import JourneyTable from './Journey';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 50,
    paddingBottom: 10
  },
  selectItem: {
    fontStyle: 'normal'
  },
  journeysFormControl: {
    display: 'block'
  },
  inputLabel: {
    marginTop: '20px',
    marginBottom: '10px'
  },
  form: {
    display: 'flow-root'
  },
  input: {
    width: '100%'
  },
  helperText: {
    marginBottom: '10px'
  },
  errorText: {
    marginBottom: '4px',
    fontWeight: 'bold',
    fontSize: '0.875rem'
  },
  errorHelperText: {
    marginBottom: '4px',
    fontSize: '0.875rem'
  },
  sendButton: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: '10px',
    flexGrow: 1,
    textTransform: 'none',
    borderRadius: '0',
    display: 'flex'
  },
  alert: {
    borderRadius: '0px',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  alertTitle: {
    fontWeight: 600
  },
  alertMessage: {
    flex: 1 /* IE11 fix */
  },
  backToStatementButton: {
    marginTop: theme.spacing(5),
    flexGrow: 1,
    textTransform: 'none',
    borderRadius: '0',
    '& img': {
      height: 12,
      width: 12
    }
  }
}));

export interface EnquiryFormData {
  reason: string;
  additional: string;
  firstname: string;
  lastname: string;
  email: string;
  number?: string;
  journeys: Journey[];
}

interface EnquiryFormProps {
  authToken: string;
  groupedJourneys: GroupedJourneys;
  onBackButtonClick: () => void;
}

const FormSubmissionState = {
  NOT_SUBMITTED: 0,
  IN_PROGRESS: 1,
  ERROR: 2,
  SUCCESS: 3
};

// Each field is represented by a bit for the purposes of validation. A
// validationBit of 10 would indicate that first name and email are invalid,
// 20 would indicate that last name and phone number are invalid, etc etc.
const ValidationFields = {
  ADDITIONAL_INFO: 1,
  FIRST_NAME: 2,
  LAST_NAME: 4,
  EMAIL: 8,
  PHONE_NUMBER: 16
};
interface MySelectDisplayProps extends React.HTMLAttributes<HTMLSelectElement> {
  'data-test'?: string;
}

const EnquiryForm = (props: EnquiryFormProps) => {
  const classes = useStyles();
  const [validationBit, setValidationBit] = useState(0);
  const [formState, setFormState] = useState(FormSubmissionState.NOT_SUBMITTED);
  const [formData, handleChange] = useForm<EnquiryFormData>({
    reason: '',
    journeys: [],
    additional: '',
    firstname: '',
    lastname: '',
    email: ''
  });
  const enquiryTitle = props.groupedJourneys.title || '';

  const validateForm = (enquiryFormData: EnquiryFormData): number => {
    let validateBit = 0;
    const nameRegex = /^[a-zA-Z '-]*$/;
    const phoneNumberRegex = /^[0-9]*$/;
    if (enquiryFormData.reason === 'Other' && !enquiryFormData.additional)
      validateBit = validateBit | ValidationFields.ADDITIONAL_INFO;
    if (!enquiryFormData.firstname.trim().match(nameRegex))
      validateBit = validateBit | ValidationFields.FIRST_NAME;
    if (!enquiryFormData.lastname.trim().match(nameRegex))
      validateBit = validateBit | ValidationFields.LAST_NAME;
    if (!validate(enquiryFormData.email))
      validateBit = validateBit | ValidationFields.EMAIL;
    if (
      enquiryFormData.number &&
      !enquiryFormData.number.match(phoneNumberRegex)
    )
      validateBit = validateBit | ValidationFields.PHONE_NUMBER;
    setValidationBit(validateBit);
    return validateBit;
  };

  const isValid = (field: number) => (validationBit & field) === field;

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (validateForm(formData) === 0) {
      setFormState(FormSubmissionState.IN_PROGRESS);

      const cardRegex = /\d{4}[ ]?\d{4}[ ]?\d{4}[ ]?(\d{4})/g;

      const dataToSend = {
        nonce: props.authToken,
        reason: formData.reason,
        additional: formData.additional.replace(cardRegex, 'XXXXXXXXXXXX$1'),
        firstname: formData.firstname,
        lastname: formData.lastname,
        email: formData.email,
        number: formData.number,
        journeys: formData.journeys,
        lastFourDigits: props.groupedJourneys.lastFourDigits,
        expiryDate: props.groupedJourneys.expiryDate,
        cardType: props.groupedJourneys.cardType,
        tokenId: props.groupedJourneys.tokenId,
        parentTokenLastFourDigits:
          props.groupedJourneys.parentTokenLastFourDigits,
        parentTokenExpirationDate:
          props.groupedJourneys.parentTokenExpirationDate,
        parentTokenCardType: props.groupedJourneys.parentTokenCardType,
        parentTokenId: props.groupedJourneys.parentTokenId
      };

      try {
        const res = await fetch(
          `${
            process.env.REACT_APP_DYNAMICS_SERVICE_URL ||
            'http://localhost:7000'
          }/cases`,
          {
            method: 'POST',
            body: JSON.stringify(dataToSend),
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        if (res.ok) {
          setFormState(FormSubmissionState.SUCCESS);
        } else {
          setFormState(FormSubmissionState.ERROR);
        }
      } catch (e) {
        console.log(e);
        setFormState(FormSubmissionState.ERROR);
      }
    }
  };

  return (
    <>
      <NavHeader
        backButton={
          formState < FormSubmissionState.SUCCESS
            ? props.onBackButtonClick
            : undefined
        }
      />
      <h1 data-test="title" className={classes.title}>
        Journey enquiry
      </h1>
      {formState < FormSubmissionState.SUCCESS && (
        <form onSubmit={handleSubmit} className={classes.form}>
          <FormControl
            component="fieldset"
            className={classes.journeysFormControl}
          >
            <FormLabel
              required
              component="legend"
              className={classes.inputLabel}
            >
              Select journey(s)
            </FormLabel>
            <FormGroup>
              <JourneyTable
                data={props.groupedJourneys}
                title={enquiryTitle}
                enquiryProps={{
                  formData: formData,
                  handleChange: handleChange
                }}
                journeys={props.groupedJourneys.journeys}
              />
            </FormGroup>
          </FormControl>
          <InputLabel
            htmlFor="enquiryReason"
            className={classes.inputLabel}
            required={true}
          >
            Enquiry reason
          </InputLabel>

          <NativeSelect
            id="enquiryReason"
            inputProps={
              { 'data-test': 'enquiryReasonSelect' } as MySelectDisplayProps
            }
            className={classes.input}
            input={<OutlinedInput />}
            onChange={(e) => handleChange('reason', e.target.value as string)}
          >
            <option value="" className={classes.selectItem}>
              Please select
            </option>
            <option value="Forgot to touch-out">Forgot to touch-out</option>
            <option value="I don't recognise a journey or charge">
              I don't recognise a journey or charge
            </option>
            <option value="I touched in and out with different devices">
              I touched in and out with different devices
            </option>
            <option value="I disagree with this charge">
              I disagree with this charge
            </option>
            <option value="Other">Other</option>
          </NativeSelect>

          {!formData.additional && (
            <InputLabel htmlFor="additionalInfo" className={classes.inputLabel}>
              Additional information (850 characters)
            </InputLabel>
          )}
          {formData.additional && (
            <InputLabel htmlFor="additionalInfo" className={classes.inputLabel}>
              Additional information ({850 - formData.additional.length}{' '}
              characters remaining)
            </InputLabel>
          )}
          <FormHelperText
            className={classes.helperText}
            id="additionalInfoAria"
            tabIndex={0}
          >
            Please include stops, time of day or anything that will help us. DO
            NOT include card details.
          </FormHelperText>
          {isValid(ValidationFields.ADDITIONAL_INFO) && (
            <FormHelperText
              data-test="missingAdditionalInfoError"
              className={classes.errorText}
              error={true}
              id="additionalReasonAria"
              tabIndex={0}
            >
              Please tell us the reason for your enquiry.
            </FormHelperText>
          )}
          <OutlinedInput
            inputRef={(input) =>
              input &&
              isValid(ValidationFields.ADDITIONAL_INFO) &&
              input.focus()
            }
            id="additionalInfo"
            className={classes.input}
            error={isValid(ValidationFields.ADDITIONAL_INFO)}
            inputProps={{ 'data-test': 'additional', maxLength: 850 }}
            multiline
            rows={4}
            onChange={(e) => handleChange('additional', e.target.value)}
            aria-describedby="additionalInfoAria additionalReasonAria"
          />
          <InputLabel
            htmlFor="firstname"
            className={classes.inputLabel}
            required={true}
          >
            First name
          </InputLabel>
          {isValid(ValidationFields.FIRST_NAME) && (
            <FormHelperText
              data-test="invalidFirstNameError"
              className={classes.errorText}
              error={true}
              id="firstnameAria"
              tabIndex={0}
            >
              Please enter a valid first name.
            </FormHelperText>
          )}
          <OutlinedInput
            inputRef={(input) =>
              input && isValid(ValidationFields.FIRST_NAME) && input.focus()
            }
            id="firstname"
            inputProps={{ 'data-test': 'firstname', maxLength: 30 }}
            className={classes.input}
            error={isValid(ValidationFields.FIRST_NAME)}
            onChange={(e) => handleChange('firstname', e.target.value)}
            aria-describedby="firstnameAria firstnameFormatAria"
          />
          {isValid(ValidationFields.FIRST_NAME) && (
            <FormHelperText
              data-test="invalidFirstNameHelp"
              className={classes.errorHelperText}
              error={true}
              id="firstnameFormatAria"
              tabIndex={0}
            >
              Use only letters, spaces, apostrophes and hyphens.
            </FormHelperText>
          )}
          <InputLabel
            htmlFor="lastname"
            className={classes.inputLabel}
            required={true}
          >
            Last name
          </InputLabel>
          {isValid(ValidationFields.LAST_NAME) && (
            <FormHelperText
              data-test="invalidLastNameError"
              className={classes.errorText}
              error={true}
              id="lastnameAria"
              tabIndex={0}
            >
              Please enter a valid last name.
            </FormHelperText>
          )}
          <OutlinedInput
            inputRef={(input) =>
              input && isValid(ValidationFields.LAST_NAME) && input.focus()
            }
            id="lastname"
            inputProps={{ 'data-test': 'lastname', maxLength: 30 }}
            className={classes.input}
            error={isValid(ValidationFields.LAST_NAME)}
            onChange={(e) => handleChange('lastname', e.target.value)}
            aria-describedby="lastnameAria lastnameFormatAria"
          />
          {isValid(ValidationFields.LAST_NAME) && (
            <FormHelperText
              data-test="invalidLastNameHelp"
              className={classes.errorHelperText}
              error={true}
              id="lastnameFormatAria"
              tabIndex={0}
            >
              Use only letters, spaces, apostrophes and hyphens.
            </FormHelperText>
          )}
          <InputLabel
            htmlFor="email"
            className={classes.inputLabel}
            required={true}
          >
            Email
          </InputLabel>
          {isValid(ValidationFields.EMAIL) && (
            <FormHelperText
              data-test="invalidEmailError"
              className={classes.errorText}
              error={true}
              id="emailAria"
              tabIndex={0}
            >
              Please enter a valid email address.
            </FormHelperText>
          )}
          <OutlinedInput
            inputRef={(input) =>
              input && isValid(ValidationFields.EMAIL) && input.focus()
            }
            id="email"
            inputProps={{ 'data-test': 'email', maxLength: 50 }}
            className={classes.input}
            error={isValid(ValidationFields.EMAIL)}
            onChange={(e) => handleChange('email', e.target.value)}
            aria-describedby="emailAria"
          />
          <InputLabel htmlFor="number" className={classes.inputLabel}>
            Phone number
          </InputLabel>
          {isValid(ValidationFields.PHONE_NUMBER) && (
            <FormHelperText
              data-test="invalidPhoneNumberError"
              className={classes.errorText}
              error={true}
              id="numberAria"
              tabIndex={0}
            >
              Please enter a valid phone number.
            </FormHelperText>
          )}
          <OutlinedInput
            inputRef={(input) =>
              input && isValid(ValidationFields.PHONE_NUMBER) && input.focus()
            }
            id="number"
            inputProps={{ 'data-test': 'number', maxLength: 30 }}
            className={classes.input}
            error={isValid(ValidationFields.PHONE_NUMBER)}
            onChange={(e) => handleChange('number', e.target.value)}
            aria-describedby="numberAria numberFormatAria"
          />
          {isValid(ValidationFields.PHONE_NUMBER) && (
            <FormHelperText
              data-test="invalidPhoneNumberHelp"
              className={classes.errorHelperText}
              error={true}
              id="numberFormatAria"
              tabIndex={0}
            >
              Use only numbers with no spaces.
            </FormHelperText>
          )}
          <Button
            id="nextButton"
            data-test="sendButton"
            variant="contained"
            color="primary"
            endIcon={<ArrowForward />}
            className={classes.sendButton}
            disabled={
              !(
                formData.reason &&
                formData.journeys.length > 0 &&
                formData.firstname &&
                formData.lastname &&
                formData.email
              ) || formState === FormSubmissionState.IN_PROGRESS
            }
            type="submit"
          >
            Send to agent
          </Button>
          {formState === FormSubmissionState.ERROR && (
            <Alert
              classes={{
                root: classes.alert,
                message: classes.alertMessage
              }}
              severity="error"
              variant="outlined"
              tabIndex={0}
            >
              <AlertTitle className={classes.alertTitle}>
                Sorry, there is a problem
              </AlertTitle>
              It looks like something went wrong on our side. We’re working on
              it, so please try again later.
            </Alert>
          )}
        </form>
      )}
      {formState === FormSubmissionState.SUCCESS && (
        <>
          <Alert
            className={classes.alert}
            severity="success"
            variant="outlined"
            icon={<CheckCircleOutlinedIcon />}
          >
            <AlertTitle className={classes.alertTitle}>Success!</AlertTitle>
            Your enquiry has been sent and we'll get back in touch shortly.
          </Alert>
          <Button
            data-test="backToStatementButton"
            variant="contained"
            color="primary"
            onClick={props.onBackButtonClick}
            className={classes.backToStatementButton}
            endIcon={<img src={arrow} alt="back to statement arrow" />}
          >
            Back to statement
          </Button>
        </>
      )}
    </>
  );
};

export default EnquiryForm;
