import moment from 'moment-timezone';
import { get7DayDateFrom } from './get7DayDate';
import { timezone } from '../data';

const groupJourneysByDay = (ticketJourneys) =>
  ticketJourneys.reduce((dayGrouped, journey) => {
    const tapOnDay = moment(journey.tapOn.dateTime)
      .tz(timezone)
      .set({ hour: 0, minutes: 0, second: 0 });

    const foundDay = dayGrouped.find(
      (day) =>
        moment(day[0].tapOn.dateTime)
          .tz(timezone)
          .set({ hour: 0, minutes: 0, second: 0 })
          .format() === tapOnDay.format()
    );

    if (foundDay) {
      foundDay.push(journey);
    } else {
      dayGrouped.push([journey]);
    }

    return dayGrouped;
  }, []);

const groupJourneysBy7Day = (travelSummaries) => {
  if (!travelSummaries) {
    return null;
  }

  const weekGrouping = {};

  travelSummaries.forEach((travelSummary) => {
    const latestJourneys = travelSummary.latestJourneys;

    if (!latestJourneys) {
      return;
    }

    latestJourneys
      .filter((journey) => journey.fareDescription.match(/WC$/))
      .forEach((journey) => {
        const startOfWeek = get7DayDateFrom(
          moment(journey.tapOn.dateTime)
            .tz(timezone)
            .format()
        );

        if (!journey.fareId) {
          return;
        }

        if (!weekGrouping[startOfWeek]) {
          weekGrouping[startOfWeek] = [];
        }

        let journeyAdded = false;
        weekGrouping[startOfWeek].forEach((ticket) => {
          const found = ticket.find(
            (addedJourney) =>
              addedJourney.fareDescription === journey.fareDescription &&
              addedJourney.fareId === journey.fareId
          );

          if (found) {
            ticket.push(journey);
            journeyAdded = true;
          }
        });

        if (!journeyAdded) {
          weekGrouping[startOfWeek].push([journey]);
        }
      });
  });

  // ensure all journeys are sorted by tapOn.dateTime and group up journeys by day
  Object.keys(weekGrouping).forEach((startOfWeekKey) => {
    weekGrouping[startOfWeekKey].forEach((ticketJourneys, i) => {
      ticketJourneys.sort(
        (a, b) =>
          moment(a.tapOn.dateTime).unix() - moment(b.tapOn.dateTime).unix()
      );

      const journeysByDay = groupJourneysByDay(ticketJourneys);
      weekGrouping[startOfWeekKey][i] = journeysByDay;
    });
  });

  // ensure all tickets are sorted by ticket name
  Object.keys(weekGrouping).forEach((startOfWeekKey) => {
    weekGrouping[startOfWeekKey].sort((a, b) =>
      a[0][0].fareDescription.localeCompare(b[0][0].fareDescription)
    );
    // .sort((a, b) => moment(a[0][0].tapOn.dateTime).unix() - moment(b[0][0].tapOn.dateTime).unix());
  });

  return weekGrouping;
};

export default groupJourneysBy7Day;
