import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { grey } from '@material-ui/core/colors';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import Head from './containers/Head';
import Version from './containers/Version';
import CalmettaRegular from './fonts/Calmetta_Rg.ttf';
import './index.css';

/* istanbul ignore file */

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    refunds: Palette['primary'];
  }
  interface PaletteOptions {
    refunds: PaletteOptions['primary'];
  }
}

const fontSource = `local(
  'CalmettaRegular'),
  url(${CalmettaRegular}) format('truetype')
`;

const calmettaRegular = {
  fontFamily: 'CalmettaRegular',
  fontStyle: 'normal',
  fontWeight: 400,
  src: fontSource,
  body: {
    color: '#fff'
  }
};

const theme = createMuiTheme({
  typography: {
    fontFamily: 'CalmettaRegular, Arial'
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitFontSmoothing: 'auto'
        },
        body: {
          backgroundColor: '#ffffff'
        },
        '@font-face': [calmettaRegular]
      }
    },
    MuiAccordion: {
      root: {
        '&:before': {
          display: 'none'
        }
      }
    }
  },
  palette: {
    primary: {
      light: grey[500],
      main: grey[900],
      dark: grey[900]
    },
    success: {
      main: '#4CA83B'
    },
    error: {
      main: '#D00505'
    },
    info: {
      main: '#0000ff'
    },
    refunds: {
      main: '#0000ff'
    }
  }
});

ReactDOM.render(
  <HelmetProvider>
    <Head />
    <Router>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </MuiThemeProvider>
    </Router>
    <Version />
  </HelmetProvider>,
  document.getElementById('root')
);
