import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import LinkButton from './LinkButton';

const EnquiryLinkRow = (props: { setEnquiryJourneys: () => void }) => {
  return (
    <TableRow>
      <TableCell colSpan={4}>
        Problem with this charge? Go{' '}
        <LinkButton
          buttonProps={{ 'data-test': 'enquiryLink' }}
          text="here"
          onClick={() => {
            props.setEnquiryJourneys();
            window.scrollTo(0, 0);
          }}
        />{' '}
        to query it
      </TableCell>
    </TableRow>
  );
};

export default EnquiryLinkRow;
