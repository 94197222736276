import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
  footer: {
    position: 'absolute',
    fontSize: 10,
    left: 10,
    bottom: 30
  },
  incompleteJourneyText: {
    textAlign: 'justify',
    marginBottom: 10
  },
  pageNumber: {
    textAlign: 'right'
  },
  boldText: {
    fontFamily: 'Pantograph Sans Bold'
  }
});

const PdfFooter = () => {
  return (
    <View style={styles.footer} fixed>
      <Text style={styles.incompleteJourneyText}>
        If you fail to touch-out at your destination stop with the same card or
        device, the <Text style={styles.boldText}>incomplete journey fare</Text>{' '}
        (currently set at the maximum single fare) is charged to your account.
        Charges for incomplete journeys do not count towards the daily cap.
      </Text>
      <Text style={styles.incompleteJourneyText}>
        If you haven’t touched-in before starting your journey, or used a
        different card or device, the contactless{' '}
        <Text style={styles.boldText}>standard fare</Text> will automatically be
        charged to the card or device you touch on the inspection device during
        the ticket check.
      </Text>
      <Text style={styles.incompleteJourneyText}>
        For more information, please visit tfgm.com/contactless.
      </Text>
      <Text
        data-testid="footerPageNumber"
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) =>
          `Page ${pageNumber} of ${totalPages}`
        }
        fixed
      />
    </View>
  );
};

export default PdfFooter;
