import React, { Dispatch, SetStateAction } from 'react';
import { Route, Switch } from 'react-router-dom';
import CardInput from './containers/CardInput';
import JourneyContainer from './containers/JourneyContainer';
import PageNotFound from './containers/PageNotFound';
import { SessionTimeouts } from './App';
interface RoutesProps {
  appProps: { isAuthorised: boolean }, // this doesn't seem to be being used.
  authToken: string,
  sessionExpired: boolean,
  sessionTimeouts: SessionTimeouts | null,
  displayTimeoutDialog: boolean,
  setAuthToken: Dispatch<SetStateAction<string>>,
  setIsAuthorised: Dispatch<SetStateAction<boolean>>,
  setDisplayTimeoutDialog: Dispatch<SetStateAction<boolean>>,
  setSessionExpired: Dispatch<SetStateAction<boolean>>,
  setSessionTimeouts: Dispatch<SetStateAction<SessionTimeouts | null>>,
}

const Routes = (props: RoutesProps) => {
  return (
    <Switch>
      <Route
        path="/journey"
        exact
        render={(renderProps) => (
          <JourneyContainer
            authToken={props.authToken}
            sessionTimeouts={props.sessionTimeouts}
            displayTimeoutDialog={props.displayTimeoutDialog}
            sessionExpired={props.sessionExpired}
          />
        )}
      />
      <Route
        path="/"
        exact
        render={(renderProps) => (
          <CardInput
            {...renderProps}
            sessionExpired={props.sessionExpired}
            setIsAuthorised={props.setIsAuthorised}
            setAuthToken={props.setAuthToken}
            setDisplayTimeoutDialog={props.setDisplayTimeoutDialog}
            setSessionExpired={props.setSessionExpired}
            setSessionTimeouts={props.setSessionTimeouts}
          />
        )}
      />
      <Route component={PageNotFound} />
    </Switch>
  );
};

export default Routes;
