import moment from 'moment-timezone';
import fareDescription from './fareDescription';
import { SelectedDates } from '../types';
import { JourneyResponse, Token } from '../queries/journeyQuery';

export const getDescriptions = (journeys: { fareDescription: string }[]) => {
  return journeys.map((journey: { fareDescription: string }) =>
    fareDescription(journey.fareDescription)
  );
};

export const isIncompleteJourney = (desc: string) =>
  desc === 'Incomplete journey';

export const filterCompleteJourneys = (descriptions: any[]) => {
  return descriptions.filter(
    (journey: string) => !isIncompleteJourney(journey)
  );
};

export const getIncompleteJourneys = (
  descriptions: any,
  isIncompleteJourney: (desc: string) => boolean
) => {
  return descriptions.filter(isIncompleteJourney);
};

export const getUniqueDescriptions = (
  completeJourneys: Iterable<unknown> | null | undefined
) => {
  return [...new Set(completeJourneys)];
};

export const getDataContainingOnlySelectedToken = (data: JourneyResponse, index: number) => {
  let cloneData: JourneyResponse = JSON.parse(JSON.stringify(data));

  if (cloneData.Journey && cloneData.Journey.Data && cloneData.Journey.Data.tokens[index]) {
    cloneData.Journey.Data.tokens = [cloneData.Journey.Data.tokens[index]];
    return cloneData;
  }

  return null;
};

export const filterBySelectedDates = (token: Token, selectedDates: SelectedDates) => {
  let clonedToken: Token = JSON.parse(JSON.stringify(token));
  const startDate = moment(selectedDates.startDate).tz('Europe/London').set({ hours: 0, minutes: 0, seconds: 0 });
  const endDate = moment(selectedDates.endDate).tz('Europe/London').set({ hours: 23, minutes: 59, seconds: 59 });

  if (clonedToken.travelSummaries) {
    clonedToken.travelSummaries = clonedToken.travelSummaries.filter((travelSummary) => {
      travelSummary.latestJourneys = travelSummary.latestJourneys.filter((journey) => {
        return moment(journey.tapOn.dateTime).valueOf() >= startDate.valueOf() &&
          moment(journey.tapOn.dateTime).valueOf() <= endDate.valueOf();
      });
      return travelSummary.latestJourneys.length > 0;
    });
  }

  if (clonedToken.inspectionTaps) {
    clonedToken.inspectionTaps = clonedToken.inspectionTaps.filter((inspectionTap) => {
      return moment(inspectionTap.dateTime).valueOf() >= startDate.valueOf() &&
          moment(inspectionTap.dateTime).valueOf() <= endDate.valueOf();
    });
  }

  return clonedToken;
}
