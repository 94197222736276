import {FormControl, makeStyles, MenuItem, Select} from '@material-ui/core';
import moment from 'moment-timezone';
import React from 'react';

const setRange: any = (start: number, end: number) => {
  if (start === end) return [start];
  return [start, ...setRange(start + 1, end)];
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: 8,
    width: '100%'
  },
  selectItem: {
    fontStyle: 'normal'
  }
}));

const formatDateRangeValue = (start: moment.Moment, end: moment.Moment): string => {
  return `${start.format('YYYY-MM-DD')}_${end.format('YYYY-MM-DD')}`;
}

const getLastSevenDaysRange = (date: Date): string => {
  const sevenDaysAgo = moment(date).subtract(7, 'days');
  const previousDay = moment(date).subtract(1, 'days');

  return formatDateRangeValue(sevenDaysAgo, previousDay);
}

const getLastMonthRange = (date: Date): string => {
  const startOfMonth = moment(date).startOf('month');
  const previousDay = moment(date).subtract(1, 'day').startOf('day');

  if (previousDay.isBefore(startOfMonth)) {
    return ``;
  }

  return formatDateRangeValue(startOfMonth, previousDay);
};

export const getExtendedStartDate = (startDate: string) => {
  const maxDaySubtract = 6;
  const startDay = moment(startDate).day();

  if (startDay !== 0) {
    return moment(startDate)
      .subtract(startDay - 1, 'd')
      .format('YYYY-MM-DD');
  }

  return moment(startDate).subtract(maxDaySubtract, 'd').format('YYYY-MM-DD');
};

export const getExtendedEndDate = (endDate: string) => {
  const weekLength = 7;
  const endDay = moment(endDate).day();

  if (endDay !== 0) {
    return moment(endDate)
      .add(weekLength - endDay, 'd')
      .format('YYYY-MM-DD');
  }

  return moment(endDate).format('YYYY-MM-DD');
};

export const MonthDropdown = (props: {
  date: Date;
  index: string;
  isDisabled: boolean;
  onSelect: (
    startDate: string,
    endDate: string,
    extendedStartDate: string,
    extendedEndDate: string
  ) => void;
  ageCallback: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const RANGE_START = 1;
  const MONTH_RANGE = 12;
  const menuItems = setRange(RANGE_START, MONTH_RANGE);

  const classes = useStyles();

  const lastSevenDays = getLastSevenDaysRange(props.date);
  const lastMonth = getLastMonthRange(props.date);

  const validateSelect = (value: string) => {
    return value !== 'select' && value !== '';
  };

  const handleChange = (event: any) => {
    const selected = event.target.value;

    if (selected !== undefined && typeof selected !== 'string') {
      return;
    }

    if (selected !== 'select' && selected !== undefined) {
      const monthYear = selected.split('_');
      const startDate = monthYear[0];
      const endDate = monthYear[1];
      const extendedStartDate = getExtendedStartDate(startDate);
      const extendedEndDate = getExtendedEndDate(endDate);

      validateSelect(selected) &&
      props.onSelect(startDate, endDate, extendedStartDate, extendedEndDate);
    }

    props.ageCallback(selected === undefined ? 'select' : selected);
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        data-test="month-drop-down"
        data-testid="month-drop-down"
        value={props.index}
        onChange={handleChange}
        onClick={handleChange}
        disabled={props.isDisabled}
      >
        <MenuItem value="select">
          <em className={classes.selectItem}>Please select</em>
        </MenuItem>
        <MenuItem
          value={lastSevenDays}
          id="sevenDays"
          data-testid="past-seven-days"
        >
          Past 7 days
        </MenuItem>
        {lastMonth !== '' &&
        <MenuItem value={lastMonth} id="monthSoFar" data-testid="month-so-far">
          Month so far
        </MenuItem>
        }
        {menuItems.map((menuItem: number, index: any) => {
          const startOfMonth = moment(props.date).subtract(menuItem, 'months').startOf('month');
          const endOfMonth = moment(props.date).subtract(menuItem, 'months').endOf('month');
          const monthRange = formatDateRangeValue(startOfMonth, endOfMonth);

          const monthYear = moment(props.date).subtract(menuItem, 'months').format('MMMM YYYY');

          return (
            <MenuItem key={index} value={monthRange}>
              {monthYear}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
