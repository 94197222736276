import React from 'react';
import Link from './Link';

const StandardFareLink = (props: {
  text: string;
  href: string;
  target?: string;
  linkProps?: any;
}) => {
  const linkText = 'here';
  const linkHref =
    'https://tfgm.com/tickets-and-passes/contactless/standard-fare-appeal';

  return <Link text={linkText} href={linkHref} target="_blank"></Link>;
};

export default StandardFareLink;
