import { makeStyles } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import { withStyles } from '@material-ui/core/styles';
import Add from '@material-ui/icons/AddCircleOutline';
import Remove from '@material-ui/icons/RemoveCircleOutline';
import React, { useState } from 'react';
import DateHeader from './DateHeader';
import Inspections from './Inspections';
import Journey from './Journey';
import Refunds from './Refunds';
import fareDescription from '../libs/fareDescription';
import { calculateAllTotals } from '../utils/calculateAllTotals';
import { penceToPound } from '../utils/penceToPound';
import { groupJourneysByFareAndOrder } from '../utils/groupJourneysByFare';
import { InspectionTap, Journey as IJourney } from '../queries/journeyQuery';

const ExpansionPanel = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    }
  }
})(MuiAccordion);

const ExpansionPanelSummary = withStyles({
  expanded: {},
  root: {
    backgroundColor: '#fafafa',
    marginBottom: 12,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
      margin: '12px 0'
    }
  },
  content: {
    margin: '12px 0',
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expandIcon: {
    position: 'absolute',
    bottom: 0,
    right: '10px',
    '&$expanded': {
      position: 'absolute',
      bottom: 0,
      right: '10px'
    }
  }
})(MuiAccordionSummary);

const useStyles = makeStyles((theme) => ({
  expansionPanelSummary: {
    paddingLeft: 0,
    paddingRight: 0,
    border: '1px solid #7a7a7a'
  },
  hidden: {
    display: 'none'
  }
}));

const generateInspections = (inspections: InspectionTap[]) => {
  const filteredInspections = inspections.filter(
    (inspection: { finalOutstandingBalance: number }) =>
      inspection.finalOutstandingBalance > 0
  );

  if (filteredInspections.length !== 0) {
    return filteredInspections.length > 1
      ? `Standard fare (${filteredInspections.length})`
      : 'Standard fare';
  }
};

const generateFares = (journeys: IJourney[], index: number) => {
  const descriptions = journeys.map((journey: { fareDescription: string }) =>
    fareDescription(journey.fareDescription)
  );

  const isIncompleteJourney = (desc: string) => desc === 'Incomplete journey';

  const completeJourneys = descriptions.filter(
    (journey: string) => !isIncompleteJourney(journey)
  );
  const incompleteJourneys = descriptions.filter(isIncompleteJourney);
  const uniqueDescriptions = [...new Set(completeJourneys)];

  const fareDescriptions = uniqueDescriptions.map(
    (description: any, idx: number) => {
      return (
        (uniqueDescriptions.length === 1 ||
          idx === uniqueDescriptions.length - 1) &&
        description
      );
    }
  );

  if (incompleteJourneys.length > 0) {
    fareDescriptions.push(`Incomplete journey (${incompleteJourneys.length})`);
  }

  return fareDescriptions;
};

const ExpansionPanelContainer = (props: any) => {
  const classes = useStyles();
  const [expandPanel, setExpandPanel] = useState(false);
  const [toggleIndex, setToggleIndex] = useState();

  const handleToggle = (event: any, index: any, expanded: any) => {
    if (toggleIndex !== index) {
      setToggleIndex(index);
    }

    setExpandPanel(expanded ? true : false);
  };

  const createJourneyLabel = (data: any, index: number) => {
    const journeys = data.journeys && generateFares(data.journeys, index);
    const inspections =
      data.inspections && generateInspections(data.inspections);
    const refunds = data.refundTotal > 0 && `Refunds issued`;

    return `${data.date.substr(0, data.date.indexOf(' '))} ${data.date.substr(
      props.data.date.indexOf(' ') + 1
    )} ${journeys} ${inspections || ''} ${refunds ||
      ''} fare total £${penceToPound(calculateAllTotals(data))}`;
  };

  return (
    <ExpansionPanel
      key={props.index}
      data-test="expansionPanel"
      data-testid="expansionPanel"
      onChange={(event, expanded) => handleToggle(event, props.index, expanded)}
    >
      <ExpansionPanelSummary
        // aria-labelledby={`journey-${props.index}`}
        aria-label={createJourneyLabel(props.data, props.index)}
        aria-controls={`additional-actions${props.index}-content`}
        id={`additional-actions${props.index}-header`}
        className={classes.expansionPanelSummary}
        expandIcon={
          toggleIndex === props.index && expandPanel ? <Remove /> : <Add />
        }
      >
        <DateHeader
          data={props.data}
          titleId={`tableTitle-${props.index}`}
          index={props.index}
        />
      </ExpansionPanelSummary>
      {props.data &&
        props.data.journeys &&
        groupJourneysByFareAndOrder(props.data.journeys).map((fareGroup) => (
          <Journey
            data={props.data}
            key={fareGroup.fareDescription}
            journeys={fareGroup.journeys}
            title={fareDescription(fareGroup.fareDescription)}
            setEnquiryJourneys={props.setEnquiryJourneys}
          />
        ))}
      {props.data.inspections && (
        <Inspections inspections={props.data.inspections} />
      )}
      {props.data.inspection && (
        <Inspections inspections={[props.data.inspection]} />
      )}
      <Refunds
        refundTotal={props.data.refundTotal}
        refunds={props.data.refunds}
      />
    </ExpansionPanel>
  );
};

export default ExpansionPanelContainer;
