import { Button, InputLabel, makeStyles, TextField } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import React, { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  iframeMock: {
    margin: 'auto',
    '& div.form-control': {
      marginBottom: '10px',
      width: '100%'
    },
    '& .submit-btn': {
      backgroundColor: '#372c31',
      fontWeight: 'bold',
      color: '#ffffff'
    }
  },
  inputLabel: {
    display: 'inline-block',
    width: '100%',
    marginBottom: '10px'
  },
  nextButton: {
    marginTop: theme.spacing(1),
    flexGrow: 1,
    textTransform: 'none',
    borderRadius: '0',
    marginBottom: 10
  },
  alertTitle: {
    fontWeight: 600
  }
}));

const IframeMock = () => {
  const classes = useStyles();
  const [status, setStatus] = useState(true);
  const [cardNumber, setCardNumber] = useState('');

  const displayMessage = () => {
    const errorMessage = (
      <Alert
        data-test="errorMessage"
        severity="error"
        variant="outlined"
        tabIndex={0}
        className={classes.alertTitle}
      >
        An error occured
      </Alert>
    );

    if (!status) {
      return errorMessage;
    }
    return;
  };

  const clickHandler = async (event: React.MouseEvent | React.FormEvent) => {
    event.preventDefault();
    const vixUrl = process.env.REACT_APP_VIX_API_URL || 'http://localhost:5000';
    try {
      const res = await axios.post(`${vixUrl}/mockapi/nonce`, {
        pan: cardNumber
      });
      window.postMessage(
        {
          event_id: 'auth_id',
          auth_token: res.data.nonce
        },
        '*'
      );
      setStatus(true);
      setCardNumber('');
    } catch (error) {
      setStatus(false);
    }
  };

  return (
    <div className={classes.iframeMock}>
      <form action="/iframe/form" method="post" onSubmit={clickHandler}>
        <div className="form-group">
          <InputLabel className={classes.inputLabel} htmlFor="card_number">
            Card number
          </InputLabel>
          <TextField
            type="text"
            className="form-control"
            id="card_number"
            value={cardNumber}
            variant="outlined"
            onChange={(e) => setCardNumber(e.target.value)}
          />
        </div>
        <Button
          id="nextButton"
          data-test="nextButton"
          endIcon={<ArrowForward />}
          variant="contained"
          color="primary"
          onClick={clickHandler}
          className={classes.nextButton}
          disabled={cardNumber === ''}
        >
          Submit
        </Button>
      </form>
      {displayMessage()}
    </div>
  );
};

export default IframeMock;
