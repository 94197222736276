import { useState } from 'react';

export type ChangeHandler<T> = <K extends keyof T>(key: K, value: T[K]) => void;

export const useForm = <T>(initialValues: T): [T, ChangeHandler<T>] => {
  const [currentValues, setCurrentValues] = useState(initialValues);

  const updateFormData: ChangeHandler<T> = (key, newValue) => {
    let newValues = { ...currentValues };
    newValues[key] = newValue;
    setCurrentValues(newValues);
  };

  return [currentValues, updateFormData];
};
