import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { penceToPound } from '../utils/penceToPound';

const BORDER_COLOR_REFUND = '#0000ff';
const COL1_WIDTH = 60;
const COL2_WIDTH = 30;
const COL3_WIDTH = 10;

const styles = StyleSheet.create({
  tableRowJourney: {
    margin: 'auto',
    flexDirection: 'row',
    backgroundColor: '#fff',
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1,
    borderTop: 1,
    borderColor: BORDER_COLOR_REFUND
  },
  tableCol1Header: {
    width: COL1_WIDTH + '%'
  },
  tableCol2Header: {
    width: COL2_WIDTH + '%'
  },
  tableCol3Header: {
    width: COL3_WIDTH + '%'
  },
  tableCell: {
    margin: 10,
    fontSize: 10
  },
  image: {
    height: 12,
    width: 12
  }
});

const PdfRefundsHeader = (props: { refundTotal: number }) => {
  return (
    <View style={styles.tableRowJourney}>
      <View style={styles.tableCol1Header}>
        <Text style={styles.tableCell}>
          <Text data-testid="refundTitle">Refunds issued</Text>
        </Text>
      </View>
      <View style={styles.tableCol2Header}>
        <Text style={styles.tableCell}></Text>
      </View>
      <View style={styles.tableCol3Header}>
        <Text data-testid="refundTotal" style={styles.tableCell}>
          {`+£${penceToPound(props.refundTotal)}*`}
        </Text>
      </View>
    </View>
  );
};

export default PdfRefundsHeader;
