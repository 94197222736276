import moment from 'moment-timezone';

// get the first Monday before the provided date
export const get7DayDateFrom = (isoDateString) => {
  if (!isoDateString) {
    return null;
  }

  const momentDateFrom = moment(isoDateString);
  // make Monday the first day of week rather than Sunday
  const day = momentDateFrom.day() === 0 ? 6 : momentDateFrom.day() - 1;

  return momentDateFrom
    .subtract(day, 'days')
    .set('hour', 0)
    .set('minute', 0)
    .set('seconds', 0)
    .format();
};

// get the first Sunday after the provided date
export const get7DayDateTo = (isoDateString) => {
  if (!isoDateString) {
    return null;
  }

  const momentDateTo = moment(isoDateString);
  // make Monday the first day of week rather than Sunday
  const day = momentDateTo.day() === 0 ? 6 : momentDateTo.day() - 1;

  return momentDateTo
    .add(6 - day, 'days')
    .set('hour', 23)
    .set('minute', 59)
    .set('seconds', 59)
    .format();
};
