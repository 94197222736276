import { makeStyles, withStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import ExpansionPanelContainer from './ExpansionPanelContainer';
import JourneyTitleHeader from './JourneyTitleHeader';
import WeeklyTickets from './WeeklyTickets';
import groupData from '../utils/groupJourneysByDay';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { featureEnabled } from '../featureFlag';
import { SelectedDates } from '../types';

const StyledTabs = withStyles({
  root: {
    background: 'light-blue',
    '& div.MuiTabs-scroller': {
      position: 'absolute',
      '& .MuiTabs-indicator': {
        background: '#fff',
        transition: 'none',
        height: '1px',
        borderRight: '1px solid #000',
        borderLeft: '1px solid #000'
      }
    }
  }
})(Tabs);

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '1em'
  },
  title: {
    textAlign: 'left',
    fontSize: '1.4em'
  },
  alert: {
    margin: 'auto',
    width: '50%',
    backgroundColor: '#232321',
    color: '#ffffff',
    borderRadius: 0
  },
  icon: {
    color: '#ffffff'
  },
  expansionPanelSummary: {
    paddingLeft: 0,
    paddingRight: 0,
    border: '1px solid #7a7a7a'
  },
  incompleteJourneyText: {
    textAlign: 'left',
    fontSize: '0.9rem'
  },
  weeklyTicketsText: {
    textAlign: 'left',
    fontSize: '1rem'
  },
  weeklyTicketsTextHeader: {
    fontSize: '1.2rem'
  },
  boxHeader: {
    display: 'inline-block',
    border: '1px solid black',
    padding: '10px'
  },
  boxSection: {
    display: 'inline-block',
    padding: '10px'
  },
  tabBar: {
    boxShadow: 'none'
  },
  tabContainer: {
    background: '#fff',
    color: '#000',
    borderBottom: '1px solid #000'
  },
  tabItem: {
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight: 'bold',
    borderTop: '1px solid #000',
    borderRight: '1px solid #000',
    borderLeft: '1px solid #000',
    marginLeft: '0.5rem'
  }
}));
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const TabbedDailyAndWeeklyView = ({
  groupedDataItem,
  setEnquiryJourneys,
  data
}: {
  groupedDataItem: any[];
  setEnquiryJourneys: any;
  data: any;
}) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      <AppBar position="static" className={classes.tabBar}>
        <StyledTabs
          className={classes.tabContainer}
          value={tabValue}
          onChange={handleTabChange}
          aria-label="daily charge and weekly ticket tabs"
        >
          <Tab
            data-testid="dailyCharges"
            data-test="dailyCharges"
            className={classes.tabItem}
            label="Daily charges"
          />
          <Tab
            data-testid="weeklyTickets"
            data-test="weeklyTickets"
            className={classes.tabItem}
            label="Weekly cap details"
          />
        </StyledTabs>
      </AppBar>
      <TabPanel value={tabValue} index={0} data-test="dailyChargesPanel">
        {/* Daily charges */}
        {groupedDataItem.map((data: any, idx: any) => {
          if (
            data.inspection &&
            data.inspection.finalOutstandingBalance === 0
          ) {
            return null;
          }

          return (
            <ExpansionPanelContainer
              index={idx}
              key={idx}
              data={data}
              setEnquiryJourneys={setEnquiryJourneys}
            />
          );
        })}
      </TabPanel>
      <TabPanel value={tabValue} index={1} data-test="weeklyTicketsPanel">
        {/* Weekly tickets */}
        <WeeklyTickets
          journeyData={data.Journey.Data}
          setEnquiryJourneys={setEnquiryJourneys}
        />
      </TabPanel>
    </>
  );
};

const DailyViewOnly = ({
  groupedDataItem,
  setEnquiryJourneys
}: {
  groupedDataItem: any[];
  setEnquiryJourneys: any;
}) => (
  <>
    {groupedDataItem.map((data: any, idx: any) => {
      if (data.inspection && data.inspection.finalOutstandingBalance === 0) {
        return null;
      }

      return (
        <ExpansionPanelContainer
          index={idx}
          key={idx}
          data={data}
          setEnquiryJourneys={setEnquiryJourneys}
        />
      );
    })}
  </>
);

const RenderJourneyList = (props: any) => {
  const classes = useStyles();

  if (props.data === undefined) {
    return <div></div>;
  } else if (props.data.Journey.Data.length === 0) {
    return (
      <Alert
        severity="warning"
        className={classes.alert}
        style={{ color: '#fff' }}
      >
        No journey record found!
      </Alert>
    );
  } else {
    const lastFourDigits = props.data.Journey.Data.lastFourDigits;
    const dateRange = props.age;

    const groupedData = groupData(props.data.Journey.Data, props.selectedDates as SelectedDates);

    return (
      <div>
        {[...groupedData].map((groupedDataItem: any[], index: number) => {
          if (groupedDataItem.length > 0) {
            const parentTokenLastFourDigits =
              groupedDataItem[0].parentTokenLastFourDigits;

            return (
              <div key={index}>
                <h1
                  data-testid="renderJourneyListHeader"
                  className={classes.title}
                >
                  Contactless Statement
                </h1>
                <JourneyTitleHeader
                  lastFourDigits={parentTokenLastFourDigits || lastFourDigits}
                  dateRange={dateRange}
                  formFactor={groupedDataItem[0].form}
                />

                {featureEnabled('CSS-976') ? (
                  <TabbedDailyAndWeeklyView
                    groupedDataItem={groupedDataItem}
                    setEnquiryJourneys={props.setEnquiryJourneys}
                    data={props.data}
                  />
                ) : (
                  <DailyViewOnly
                    groupedDataItem={groupedDataItem}
                    setEnquiryJourneys={props.setEnquiryJourneys}
                  />
                )}

                <div className={classes.incompleteJourneyText}>
                  <p>
                    If you fail to touch-out at your destination stop with the
                    same card or device, the <b>incomplete journey fare</b>{' '}
                    (currently set at the maximum single fare) is charged to
                    your account. Charges for incomplete journeys do not count
                    towards the daily cap.
                  </p>

                  <p>
                    If you haven’t touched-in before starting your journey, or
                    used a different card or device, the contactless{' '}
                    <b>standard fare</b> will automatically be charged to the
                    card or device you touch on the inspection device during the
                    ticket check.
                  </p>
                </div>
              </div>
            );
          }

          return null;
        })}
      </div>
    );
  }
};

export default RenderJourneyList;
