import { FormGroup, FormLabel, makeStyles } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AlertTitle } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import { PDFDownloadLink, StyleSheet } from '@react-pdf/renderer';
import React, { Fragment, useState, Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import {
  QueryLazyOptions,
  OperationVariables,
  ApolloError
} from '@apollo/client';
import { MonthDropdown } from './MonthDropdown';
import NavHeader from './NavHeader';
import PdfDocument from './PdfDocument';
import RenderJourneyList from './RenderJourneyList';
import { TokenDropdown } from './TokenDropdown';
import { getDataContainingOnlySelectedToken, filterBySelectedDates } from '../libs/utils';
import { SelectedDates } from '../types';
import { GroupedJourneys } from '../utils/groupJourneysByDay';
import { JourneyResponse } from '../queries/journeyQuery';
import { SessionTimeouts } from '../App';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fafafa'
  },
  dateSearchForm: {
    margin: 'auto',
    marginBottom: '40px',
    width: '100%',
    '& legend': {
      paddingLeft: 0
    },
    '& div': {
      marginLeft: 0
    }
  },
  journeyData: {
    '& .submit-btn': {
      backgroundColor: '#362c31',
      fontWeight: 'bold',
      color: '#ffffff',
      padding: '0.5em 1em'
    }
  },
  statementTitle: {
    fontWeight: 50,
    paddingBottom: '10px'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  selectMonthLabel: {
    paddingLeft: '10px'
  },
  selectTokenLabel: {
    paddingTop: '20px',
    paddingLeft: '10px'
  },
  alert: {
    borderRadius: '0px',
    color: 'rgba(0, 0, 0, 0.87)',
    textAlign: 'left'
  },
  alertTitle: {
    fontWeight: 600
  },
  alertMessage: {
    flex: 1 /* IE11 fix */
  },
  progress: {
    color: '#fffff',
    width: 30,
    height: 30
  },
  downloadLinkDisabled: {
    color: '#767676',
    textAlign: 'center',
    alignContent: 'center',
    textDecoration: 'none',
    padding: '15px',
    backgroundColor: '#ffffff',
    fontWeight: 'normal',
    paddingLeft: '10px',
    paddingRight: '10px',
    display: 'flex',
    margin: 'auto',
    justifyContent: 'center',
    zIndex: -1,
    marginBottom: '40px',
    cursor: 'pointer',
    border: '1px solid #767676',
    fontSize: '1rem'
  },
  title: {
    fontWeight: 100
  },

  formControl: {
    width: '100%',
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    marginTop: 8
  },
  searchButton: {
    color: '#fffff',
    textAlign: 'center',
    alignContent: 'center',
    textDecoration: 'none',
    padding: '15px',
    backgroundColor: '#3a3a3a',
    fontWeight: 'normal',
    display: 'flex',
    margin: 'auto',
    minWidth: '200px',
    justifyContent: 'center',
    textTransform: 'none',
    marginTop: 20,
    borderRadius: 0,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 10
  }
}));

const hasDataWithinSelectedDates = (data: JourneyResponse, selectedTokenIndex: number, selectedDates: SelectedDates) => {
  if (!data.Journey || !data.Journey.Data || !data.Journey.Data.tokens || data.Journey.Data.tokens.length === 0) {
    return false;
  }

  const filteredData = filterBySelectedDates(data.Journey.Data.tokens[selectedTokenIndex], selectedDates);

  return filteredData.travelSummaries.length > 0 || filteredData.inspectionTaps.length > 0;
}

interface JourneyDataProps {
  age: string;
  setAge: Dispatch<SetStateAction<string>>;
  setEnquiryJourneys: Dispatch<SetStateAction<GroupedJourneys | null>>;
  selectedDates?: SelectedDates;
  onDateSelection: (startDate: string, endDate: string) => void;
  fetchJourneyData: (options?: QueryLazyOptions<OperationVariables>) => void;
  error?: ApolloError;
  data?: JourneyResponse;
  loading: boolean;
  sessionTimeouts: SessionTimeouts | null;
  displayTimeoutDialog: boolean;
  sessionExpired: boolean;
}

export const JourneyData = (props: JourneyDataProps) => {
  const classes = useStyles();
  const [selectedTokenIndex, setSelectedTokenIndex] = useState(0);
  const history = useHistory();
  const styles = StyleSheet.create({
    downloadLink: {
      color: '#000000',
      textAlign: 'center',
      alignContent: 'center',
      textDecoration: 'none',
      padding: '15px',
      backgroundColor: '#ffffff',
      fontWeight: 'normal',
      paddingLeft: '10px',
      paddingRight: '10px',
      display: 'flex',
      margin: 'auto',
      border: '1px solid #000000',
      justifyContent: 'center',
      width: '60%',
      fontSize: '1rem'
    }
  });

  const buildStatementComponent = (
    disableSelect: boolean,
    onSelect: (startDate: string, endDate: string) => void
  ) => {
    return (
      <FormGroup>
        <FormLabel
          data-test="select-month"
          component="legend"
          className={classes.selectMonthLabel}
        >
          Select statement period
        </FormLabel>
        <MonthDropdown
          date={new Date()}
          index={props.age === '' ? 'select' : props.age}
          isDisabled={disableSelect}
          onSelect={(
            startDate,
            endDate,
            extendedStartDate,
            extendedEndDate
          ) => {
            props.fetchJourneyData({
              variables: {
                startDate: extendedStartDate,
                endDate: extendedEndDate
              }
            });
            onSelect(startDate, endDate);
          }}
          ageCallback={props.setAge}
        />
        {props.data &&
          props.error === undefined &&
          props.data.Journey.Data.tokens.length > 1 && (
            <div>
              <FormLabel
                data-test="select-token"
                component="legend"
                className={classes.selectTokenLabel}
              >
                Card/device
              </FormLabel>
              <TokenDropdown
                index={selectedTokenIndex}
                tokens={props.data.Journey.Data.tokens}
                tokenCallback={setSelectedTokenIndex}
              />
            </div>
          )}
      </FormGroup>
    );
  };

  const dataContainingOnlySelectedToken = props.data && getDataContainingOnlySelectedToken(props.data, selectedTokenIndex);

  const renderPdfDocument = (data: any, age: any) => {
    // clearTimeout(props.sessionTimeouts.expiryWarningTimeout);
    // console.log('cleared warning timeout...');
    return (
      <PdfDocument
        data={props.data}
        age={props.age}
        selectedDates={props.selectedDates}
      />
    );
  };

  console.log('props.error', props.error);
  console.log('props.selectedDates', props.selectedDates);
  console.log('tokenHasDataWithinSelectedDates', props.data && props.selectedDates && hasDataWithinSelectedDates(props.data, selectedTokenIndex, props.selectedDates));
  console.log('dataContainingOnlySelectedToken', dataContainingOnlySelectedToken);

  return (
    <>
      <div className={classes.journeyData}>
        <NavHeader
          backButton={() => {
            if (props.sessionTimeouts) {
              clearTimeout(props.sessionTimeouts.expiryWarningTimeout);
              clearTimeout(props.sessionTimeouts.expiryTimeout);
            }

            history.push('/');
          }}
        />
        <h1 data-test="statementPeriod" className={classes.statementTitle}>
          Contactless statement
        </h1>
        <form className={classes.dateSearchForm}>
          {buildStatementComponent(false, props.onDateSelection)}
        </form>
        {props.error && props.error.graphQLErrors.length > 0 && (
          <Fragment>
            <Alert
              classes={{
                root: classes.alert,
                message: classes.alertMessage
              }}
              severity="error"
              variant="outlined"
              tabIndex={0}
            >
              <AlertTitle className={classes.alertTitle}>
                Sorry, there is a problem
              </AlertTitle>
              {(() => {
                const e = props.error.graphQLErrors[0];
                if (e.extensions) {
                  if (e.extensions.code === '004') {
                    return 'Your session has expired. Please click on the back arrow above to start again.';
                  } else {
                    return e.message;
                  }
                } else {
                  return e.message;
                }
              })()}
            </Alert>
          </Fragment>
        )}
        {props.data &&
          !props.error &&
          props.data.Journey.Data.tokens.reduce((acc, _, i) => {
            if (acc === false && props.selectedDates && props.data && hasDataWithinSelectedDates(props.data, i, props.selectedDates)) {
              acc = true;
            }

            return acc;
          }, false) &&
          !props.displayTimeoutDialog &&
          !props.sessionExpired && (
            <PDFDownloadLink
              className="button-pdf"
              data-test="button-pdf"
              document={renderPdfDocument(props.data, props.age)}
              fileName={`${props.age}.pdf`}
              style={styles.downloadLink}
            >
              {({ blob, url, loading, error }) =>
                loading ? 'Loading...' : 'Download statement \u2913'
              }
            </PDFDownloadLink>
          )}
        {!props.error &&
            props.data &&
            props.selectedDates &&
            !hasDataWithinSelectedDates(props.data, selectedTokenIndex, props.selectedDates) && (
              <Fragment>
                <Alert
                  className={classes.alert}
                  severity="error"
                  variant="outlined"
                  tabIndex={0}
                >
                  <AlertTitle className={classes.alertTitle}>
                    Sorry, there is a problem
                  </AlertTitle>
                  No journeys found for this period and payment type.
                </Alert>
              </Fragment>
            )}
      </div>
      <div>
        {dataContainingOnlySelectedToken && !props.error && (
          <RenderJourneyList
            selectedDates={props.selectedDates}
            setEnquiryJourneys={props.setEnquiryJourneys}
            data={dataContainingOnlySelectedToken}
            age={props.age}
          />
        )}
      </div>
      <Backdrop className={classes.backdrop} open={props.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
