/* istanbul ignore file */
import URLSearchParams from '@ungap/url-search-params';

const isInUrl = (id: string) => {
  const urlParams = new URLSearchParams(window.location.search);

  if (urlParams.has('ff')) {
    return urlParams.getAll('ff').includes(id);
  }

  return false;
};

export const featureEnabled = (id: string) => {
  if (process.env.NODE_ENV === 'development' || process.env.CI) {
    return true;
  }

  if (!isInUrl(id)) {
    return true;
  }

  return true;
};
