import { FormControl, makeStyles, MenuItem, Select } from '@material-ui/core';
import React, { useRef } from 'react';
import { Token } from '../queries/journeyQuery';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: 8,
    width: '100%'
  }
}));

const convertFormFactor = (formFactor: string, lastFourDigits: any) => {
  switch (formFactor) {
    case 'mini card':
    case 'card':
      return `Card (Ending ${lastFourDigits})`;
    case 'mobile phone':
      return `Mobile phone (Ending ${lastFourDigits})`;
    case 'watch':
      return `Watch (Ending  ${lastFourDigits})`;
    default:
      return `Other (Ending ${lastFourDigits})`;
  }
};

export const TokenDropdown = (props: {
  index: number;
  tokens: Token[];
  tokenCallback: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const classes = useStyles();

  const refInput = useRef(null);

  const handleChange = (event: any) => {
    const selected = event.target.value;
    props.tokenCallback(selected);
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Select
        ref={refInput}
        labelId="token-select-outlined-label"
        id="token-select-outlined"
        data-test="token-drop-down"
        value={props.index}
        onChange={handleChange}
      >
        {props.tokens.map((token, index) => {
          return (
            <MenuItem key={index} value={index}>
              {convertFormFactor(token.formFactor, token.lastFourDigits)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
