import React from 'react';
import { makeStyles } from '@material-ui/core';
import Inspection from './Inspection';
import { InspectionTap } from '../queries/journeyQuery';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    border: 0
  },
  tableJourney: {
    marginBottom: 10
  }
}));

const Inspections = (props: { inspections: InspectionTap[] }) => {
  const classes = useStyles();

  return (
    <Paper variant="outlined" square className={classes.paper}>
      <TableContainer
        data-testid="completedJourney"
        className={classes.tableJourney}
      >
        <Table size="medium">
          <TableBody>
            {props.inspections.map((inspection, index: number) => {
              return <Inspection inspection={inspection} key={index} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default Inspections;
