import React from 'react';
import { convertToBST } from '../utils/dateTimeFormat';
import { makeStyles, Checkbox } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { EnquiryFormData } from './EnquiryForm';
import { ChangeHandler } from '../libs/hooksLib';
import { Journey } from '../queries/journeyQuery';
import arrow from '../images/arrow-right.svg';
import arrowPng from '../images/arrow-right.png';

const useStyles = makeStyles((theme) => ({
  journeyRow: {
    backgroundColor: '#ffffff'
  },
  journeyStart: {
    width: '48%'
  },
  journeyEnd: {
    width: '60%'
  },
  image: {
    height: 12,
    width: 12
  },
  missingTouch: {
    color: theme.palette.error.main
  }
}));

const JourneyRow = (props: {
  journey: Journey;
  enquiryProps?: {
    formData: EnquiryFormData;
    handleChange: ChangeHandler<EnquiryFormData>;
  };
  isSingleFare: boolean;
  isResidual: boolean;
}) => {
  const classes = useStyles();
  const handleClick = (event: React.MouseEvent<unknown>, journey: Journey) => {
    if (props.enquiryProps) {
      const selectedIndex = props.enquiryProps.formData.journeys
        .map((j) => j.tapOn.dateTime + j.tapOff.dateTime) // might need to think of a better way of doing this
        .indexOf(journey.tapOn.dateTime + journey.tapOff.dateTime);
      let newSelected: Journey[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(
          props.enquiryProps.formData.journeys,
          journey
        );
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(
          props.enquiryProps.formData.journeys.slice(1)
        );
      } else if (
        selectedIndex ===
        props.enquiryProps.formData.journeys.length - 1
      ) {
        newSelected = newSelected.concat(
          props.enquiryProps.formData.journeys.slice(0, -1)
        );
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          props.enquiryProps.formData.journeys.slice(0, selectedIndex),
          props.enquiryProps.formData.journeys.slice(selectedIndex + 1)
        );
      }
      props.enquiryProps.handleChange('journeys', newSelected);
    }
  };

  const isSelected = (journey: Journey) =>
    props
      .enquiryProps!.formData.journeys.map(
        (j) => j.tapOn.dateTime + j.tapOff.dateTime
      )
      .indexOf(journey.tapOn.dateTime + journey.tapOff.dateTime) !== -1;

  return (
    <TableRow
      className={classes.journeyRow}
      tabIndex={-1}
      onClick={(event) => handleClick(event, props.journey)}
      data-test="completedJourneyRow"
      data-testid="completedJourneyRow"
    >
      {props.enquiryProps && (
        <TableCell padding="checkbox">
          <Checkbox
            checked={isSelected(props.journey)}
            color="default"
            data-test="journeyCheckbox"
            inputProps={{
              'aria-label': `${
                props.journey.tapOn.location || 'Missing touch'
              } to ${props.journey.tapOff.location || 'Missing touch'}`
            }}
          />
        </TableCell>
      )}
      <TableCell
        align="left"
        className={classes.journeyStart}
        colSpan={2}
        data-testid="tapOnDateTime"
        data-test="tapOnDateTime"
      >
        <strong data-test="tapOnLocation">
          {props.journey.tapOn.location ? (
            <>
              {props.isSingleFare || props.isResidual
                ? `${props.journey.tapOn.location} (Z${props.journey.tapOn.zone})`
                : props.journey.tapOn.location}
            </>
          ) : (
            <span
              data-test="tapOnLocationMissing"
              className={classes.missingTouch}
            >
              Missing touch
            </span>
          )}
        </strong>
        <br />
        {props.journey.tapOn.location &&
          convertToBST(props.journey.tapOn.dateTime).format('HH:mm')}
      </TableCell>
      <TableCell align="center">
        <img
          className={`${classes.image} ieJourneyArrow`}
          src={arrow}
          alt="journey arrow"
        />
        <object
          className={`${classes.image} journeyArrow`}
          type="image/svg+xml"
          data={arrow}
          tabIndex={-1}
          aria-label="Journey Arrow"
        >
          <img className={classes.image} src={arrowPng} alt="journey arrow" />
        </object>
      </TableCell>
      <TableCell
        align="left"
        className={classes.journeyEnd}
        data-testid="tapOffDateTime"
        data-test="tapOffDateTime"
      >
        <strong data-test="tapOffLocation">
          {props.journey.tapOff.location ? (
            <>
              {props.isSingleFare || props.isResidual
                ? `${props.journey.tapOff.location} (Z${props.journey.tapOff.zone})`
                : props.journey.tapOff.location}
            </>
          ) : (
            <span
              data-test="tapOffLocationMissing"
              className={classes.missingTouch}
            >
              Missing touch
            </span>
          )}
        </strong>
        <br />
        {props.journey.tapOff.location &&
          convertToBST(props.journey.tapOff.dateTime).format('HH:mm')}
      </TableCell>
    </TableRow>
  );
};

export default JourneyRow;
