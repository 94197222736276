import { makeStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import missingJourneyPng from '../images/missing-journey.png';
import missingJourney from '../images/missing-journey.svg';
import { calculateJourneyTotals } from '../utils/journeyTotal';
import { penceToPound } from '../utils/penceToPound';

const useStyles = makeStyles((theme) => ({
  journeyIcon: {
    height: '20px',
    width: '20px',
    paddingTop: 0
  },
  journeyIconContainer: {
    height: '20px',
    width: '20px',
    paddingTop: '2px'
  },
  tableHeaderRow: {
    borderTop: `1px solid ${theme.palette.error.main}`,
    borderBottom: `1px solid ${theme.palette.error.main}`,
    backgroundColor: '#ffffff'
  },
  tableHeaderImage: {
    borderLeft: `1px solid ${theme.palette.error.main} !important`,
    verticalAlign: 'middle',
    width: '10%',
    paddingRight: 0
  },
  tableHeaderJourney: {
    verticalAlign: 'middle',
    width: '35%',
    paddingLeft: 0,
    paddingRight: 0
  },
  tableHeader: {
    borderRight: `1px solid ${theme.palette.error.main} !important`,
    verticalAlign: 'middle'
  }
}));

const IncompleteJourneyHeader = (props: {
  journeys: any;
  headerColSpan: number;
  refund: boolean;
}) => {
  const classes = useStyles();

  return (
    <TableRow
      className={classes.tableHeaderRow}
      data-test="incompleteJourneyHeader"
    >
      <TableCell className={classes.tableHeaderImage} align="left" colSpan={1}>
        <img
          className={`${classes.journeyIcon} ieMissingJourney`}
          src={missingJourney}
          alt="missing journey"
        />
        <object
          className={`${classes.journeyIcon} missingJourney`}
          type="image/svg+xml"
          data={missingJourney}
          tabIndex={-1}
          aria-label="Missing Journey"
        >
          <img
            className={classes.journeyIcon}
            src={missingJourneyPng}
            alt="missing journey"
          />
        </object>
      </TableCell>
      <TableCell
        className={classes.tableHeaderJourney}
        align="left"
        data-testid="missingTouchsTitle"
      >
        <strong data-test="missingTouchsTitle">Incomplete journeys</strong>
      </TableCell>
      <TableCell
        className={classes.tableHeader}
        align="right"
        colSpan={props.headerColSpan}
      >
        <strong data-testid="missingTouchsTotal" data-test="missingTouchsTotal">
          {`£${penceToPound(
            calculateJourneyTotals(props.journeys).toString()
          )}`}
          {props.refund && '*'}
        </strong>
      </TableCell>
    </TableRow>
  );
};

export default IncompleteJourneyHeader;
