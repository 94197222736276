import React from 'react';
import { makeStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { penceToPound } from '../utils/penceToPound';

const useStyles = makeStyles((theme) => ({
  journeyRow: {
    backgroundColor: '#ffffff'
  },
  weeklyJourneyRefund: {
    padding: '0 15px 15px 15px',
    color: '#0000ff'
  }
}));

const WeeklyRefundRow = (props: { refundedTotal: number }) => {
  const classes = useStyles();

  return (
    <TableRow
      className={classes.journeyRow}
      tabIndex={-1}
      data-test="weeklyJourneyRefundRow"
    >
      <TableCell
        align="left"
        colSpan={3}
        className={classes.weeklyJourneyRefund}
        data-testid="weeklyJourneyRefund"
        data-test="weeklyJourneyRefund"
      >
        &#9492; &nbsp;Refund issued + {`£${penceToPound(props.refundedTotal)}`}
      </TableCell>
    </TableRow>
  );
};

export default WeeklyRefundRow;
