import moment from 'moment';
import { convertToBST } from './dateTimeFormat';
import { Journey, InspectionTap, JourneyData } from '../queries/journeyQuery'
import { filterBySelectedDates } from '../libs/utils';
import { SelectedDates } from '../types';
export interface GroupedJourneys {
  title?: string;
  date: string;
  form: string;
  lastFourDigits: string;
  expiryDate: string;
  cardType: string;
  tokenId: string;
  parentTokenLastFourDigits: string;
  parentTokenExpirationDate: string;
  parentTokenCardType: string;
  parentTokenId: string;
  journeys: Journey[];
  refunds: Refund[];
  refundTotal: number;
  inspections?: InspectionTap;
}

export interface JourneyFareGroup {
  fareDescription: string;
  journeys: Journey[];
}

export interface Refund {
  dateTime: string;
  refundTotal: number;
}

export interface GroupedInspections {
  date: string;
  lastFourDigits: string;
  parentTokenLastFourDigits: string;
  inspection: InspectionTap;
}

const dateFormat = 'dddd D MMMM YYYY';

const groupData = (datax: JourneyData, selectedDates: SelectedDates) => {
  let clonedToken: JourneyData = JSON.parse(JSON.stringify(datax));

  const combinedJourneyObjects = clonedToken.tokens.map((token) => {
    token = filterBySelectedDates(token, selectedDates);

    const formFactor = token.formFactor;
    const lastFourDigits = token.lastFourDigits;
    const expiryDate = token.expiryDate;
    const cardType = token.cardType;
    const tokenId = token.token;
    const parentTokenLastFourDigits = token.parentTokenLastFourDigits;
    const parentTokenExpirationDate = token.parentTokenExpirationDate;
    const parentTokenCardType = token.parentTokenCardType;
    const parentTokenId = token.parentToken;

    const travelSummaries: GroupedJourneys[] = token.travelSummaries.map(
      (travelSummary) => {
        const journeyDate = convertToBST(
          travelSummary.latestJourneys[0].tapOn.dateTime.toLocaleString()
        ).format(dateFormat);

        const refunds = travelSummary.settlementHistory
          ? travelSummary.settlementHistory
              .filter(
                (s) => s.type === 'refund' && s.status === 'accepted'
              )
              .map((s) => ({
                ...s,
                refundTotal: s.journeys
                  .map((refundJourney) =>
                    Math.abs(refundJourney.valueRefunded)
                  )
                  .reduce((prev: number, current: number) => prev + current)
              }))
              .filter((s) => s.refundTotal > 0)
          : [];

        return {
          date: journeyDate,
          form: formFactor,
          lastFourDigits,
          expiryDate,
          cardType,
          tokenId,
          parentTokenLastFourDigits,
          parentTokenExpirationDate,
          parentTokenCardType,
          parentTokenId,
          journeys: travelSummary.latestJourneys.map((latestJourney) => ({
            ...latestJourney,
            refund: refunds.some((refund) =>
              refund.journeys.some(
                (refundJourney) =>
                  refundJourney.fareRequestIdentifier ===
                  latestJourney.fareRequestIdentifier
              )
            )
          })),
          refundTotal: refunds
            .map((refund) => refund.refundTotal)
            .reduce((prev: number, current: number) => prev + current, 0),
          refunds
        };
      }
    );

    const inspectionTaps: GroupedInspections[] = token.inspectionTaps.map(
      (inspectionTap) => {
        const last4Digits = token.lastFourDigits;
        const inspectionDate = moment(
          inspectionTap.dateTime.toLocaleString()
        ).format(dateFormat);

        return {
          date: inspectionDate,
          lastFourDigits: last4Digits,
          parentTokenLastFourDigits,
          inspection: inspectionTap,
        };
      }
    );

    const journeyDates = travelSummaries.map(
      (travelSummary) => travelSummary.date
    );

    const inspectionDates = inspectionTaps.map((tap) => tap.date);

    const soloInspections: GroupedInspections[] = inspectionTaps.filter(
      (tap) => !journeyDates.includes(tap.date)
    );

    const combinedJourneys: GroupedJourneys[] = travelSummaries.map(
      (travelSummary: any) => {
        if (inspectionDates.includes(travelSummary.date)) {
          const sameDayinspectionTaps = inspectionTaps
            .filter((tap: any) => tap.date === travelSummary.date)
            .map((inspectionTap: any) => inspectionTap.inspection);

          const combinedSummary = {
            ...travelSummary,
            inspections: [...sameDayinspectionTaps]
          };

          return combinedSummary;
        } else {
          return travelSummary;
        }
      }
    );

    return [...combinedJourneys, ...soloInspections] as [
      GroupedJourneys,
      GroupedInspections
    ];
  });

  return combinedJourneyObjects;
};

export default groupData;
