import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    paddingTop: 130,
  },
}));

const PageNotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <h3 data-testid="notFoundHeader">Page not found.</h3>
    </div>
  );
};

export default PageNotFound;
