import { Document, Font, Page, StyleSheet, View } from '@react-pdf/renderer';
import React from 'react';
import PdfDateHeader from './PdfDateHeader';
import PdfFooter from './PdfFooter';
import PdfHeader from './PdfHeader';
import PdfInspections from './PdfInspections';
import PdfJourney from './PdfJourney';
import PdfRefunds from './PdfRefunds';
import PdfTitleHeader from './PdfTitleHeader';
import fontBold from '../fonts/PantographSansBold.ttf';
import font from '../fonts/PantographSansRegular.ttf';
import groupData from '../utils/groupJourneysByDay';
import { groupJourneysByFareAndOrder } from '../utils/groupJourneysByFare';
import fareDescription from '../libs/fareDescription';

const BORDER_COLOR_JOURNEY = '#bfbfbf';
const BORDER_STYLE = 'solid';

Font.register({
  family: 'Pantograph Sans Regular',
  format: 'truetype',
  src: font,
  fontStyle: 'normal',
  fontWeight: 'normal'
});

Font.register({
  family: 'Pantograph Sans Bold',
  format: 'truetype',
  src: fontBold,
  fontStyle: 'normal',
  fontWeight: 'normal'
});

const styles = StyleSheet.create({
  body: {
    padding: 30,
    paddingBottom: 120,
    fontFamily: 'Pantograph Sans Regular'
  },
  tableJourney: {
    display: 'flex',
    width: 'auto',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR_JOURNEY,
    borderWidth: 1,
    marginBottom: 10
  },
  boldText: {
    fontFamily: 'Pantograph Sans Bold'
  }
});

const PdfDocument = (props: any) => {
  if (props.data !== undefined && props.data.Journey !== null) {
    const lastFourDigits = props.data.Journey.Data.lastFourDigits;
    const dateRange = props.age;

    const groupedData = groupData(props.data.Journey.Data, props.selectedDates);

    return (
      <Document>
        {groupedData.map((groupedDataItem: any[], index: number) => {
          if (groupedDataItem.length > 0) {
            const parentTokenLastFourDigits =
              groupedDataItem[0].parentTokenLastFourDigits;

            return (
              <Page size="A4" style={styles.body} key={index} wrap={true}>
                <PdfHeader />
                <PdfTitleHeader
                  lastFourDigits={parentTokenLastFourDigits || lastFourDigits}
                  dateRange={dateRange}
                  formFactor={groupedDataItem[0].form}
                />

                {groupedDataItem.map((data, idx: number) => {
                  return (
                    <View wrap={false} key={idx}>
                      {data.inspection &&
                      data.inspection.finalOutstandingBalance === 0 ? null : (
                        <PdfDateHeader data={data} index={idx} />
                      )}

                      {data.journeys &&
                        groupJourneysByFareAndOrder(
                          data.journeys
                        ).map((fareGroup) => (
                          <PdfJourney
                            key={fareGroup.fareDescription}
                            title={fareDescription(fareGroup.fareDescription)}
                            journeys={fareGroup.journeys}
                          />
                        ))}

                      {data.inspections && (
                        <PdfInspections inspections={data.inspections} />
                      )}
                      {data.inspection && (
                        <PdfInspections inspections={[data.inspection]} />
                      )}
                      <PdfRefunds
                        refundTotal={data.refundTotal}
                        refunds={data.refunds}
                      />
                    </View>
                  );
                })}
                <PdfFooter />
              </Page>
            );
          }

          return null;
        })}
      </Document>
    );
  }

  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View></View>
      </Page>
    </Document>
  );
};

export default PdfDocument;
