import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  DefaultOptions,
} from '@apollo/client'

const httpLink = (headers?: any) => {
  return new HttpLink({
    uri: process.env.REACT_APP_API_URL || 'http://localhost:4000/graphql',
    headers: headers,
  });
};

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

export const client = (headers?: any) => {
  return new ApolloClient({
    link: httpLink(headers),
    cache: new InMemoryCache(),
    defaultOptions,
  });
};
