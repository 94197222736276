import React from 'react';
import { penceToPound } from '../utils/penceToPound';
import { calculateJourneyTotals } from '../utils/journeyTotal';
import { makeStyles } from '@material-ui/core';
import ticket from '../images/ticket.svg';
import ticketPng from '../images/ticket.png';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    border: '1px solid #e0e0e0',
    backgroundColor: '#ffffff',
    display: 'flex',
    padding: '16px',
    paddingLeft: '8px',
    paddingBottom: '12px',
    alignItems: 'flex-end'
  },
  icon: {
    paddingTop: '2px',
    position: 'relative',
    top: '-1px'
  },
  journeyIcon: {
    height: '20px',
    width: '20px'
  },
  title: {
    paddingLeft: '8px'
  },
  fare: {
    flexGrow: 1,
    textAlign: 'right'
  },
  image: {
    height: 12,
    width: 12
  }
}));

const JourneyHeader = ({
  journeys,
  refund,
  title
}: {
  journeys: any;
  headerColSpan: number;
  refund: boolean;
  title: string;
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper} data-test="completedJourneyHeader">
      <div className={classes.icon}>
        <img
          className={`${classes.journeyIcon} ieJourney`}
          src={ticket}
          alt="journey"
        />
        <object
          className={`${classes.journeyIcon} journey`}
          type="image/svg+xml"
          data={ticket}
          tabIndex={-1}
          aria-label="Completed Journeys"
        >
          <img className={classes.journeyIcon} src={ticketPng} alt="journey" />
        </object>
      </div>
      <div className={classes.title}>
        <strong data-test="completedJourneyHeaderTitle">{title}</strong>
      </div>
      <div className={classes.fare} data-testid="fareTotal">
        <strong data-test="fareTotal">
          {`£${penceToPound(calculateJourneyTotals(journeys).toString())}`}
          {refund && '*'}
        </strong>
      </div>
    </div>
  );
};

export default JourneyHeader;
