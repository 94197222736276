import { Journey } from '../queries/journeyQuery';
import fareDescription from '../libs/fareDescription';
import { JourneyFareGroup } from './groupJourneysByDay';
import moment from 'moment';

export const groupJourneysByFare = (journeys: Journey[]) => {
  const initialValue: Record<string, Journey[]> = {};

  // group by fareDescription rules
  const grouped = journeys.reduce((accumulator, currentJourney) => {
    let code = currentJourney.fareDescription;
    // eslint-disable-next-line
    if (code && code.match(/\-SF$/)) {
      code = 'SF';
    } else if (fareDescription(code) === 'Incomplete journey') {
      code = 'IJ';
    }

    if (!accumulator[code]) {
      accumulator[code] = new Array<Journey>();
    }
    accumulator[code].push(currentJourney);
    return accumulator;
  }, initialValue);

  // sort the journeys under each group by journey.tapOn date
  Object.keys(grouped).forEach((fareDescription) => {
    grouped[fareDescription].sort(
      (a, b) =>
        moment(a.tapOn.dateTime).valueOf() - moment(b.tapOn.dateTime).valueOf()
    );
  });

  return grouped;
};

const fareGroupPriority = (journeyFareGroup: JourneyFareGroup) => {
  // is a 7 day capped fare grouping
  // eslint-disable-next-line
  if (journeyFareGroup.fareDescription.match(/\-WC$/)) {
    return 10;
  }

  // is a daily capped fare grouping
  // eslint-disable-next-line
  if (journeyFareGroup.fareDescription.match(/\-P?C$/)) {
    return 20;
  }

  // is the single fare grouping
  if (journeyFareGroup.fareDescription === 'SF') {
    return 30;
  }

  // is the incomplete journey grouping
  if (journeyFareGroup.fareDescription === 'IJ') {
    return 40;
  }

  // unknown
  return 50;
};

export const groupJourneysByFareAndOrder = (
  journeys: Journey[]
): JourneyFareGroup[] => {
  const groupedJourneysByFare = groupJourneysByFare(journeys);
  const orderedList = Object.keys(groupedJourneysByFare)
    .map((code) => ({
      fareDescription: code,
      journeys: groupedJourneysByFare[code]
    }))
    .sort((a, b) => {
      let priority = fareGroupPriority(a) - fareGroupPriority(b);
      if (a.fareDescription < b.fareDescription) {
        priority = priority - 1;
      }
      if (a.fareDescription > b.fareDescription) {
        priority = priority + 1;
      }
      return priority;
    });

  return orderedList;
};
