import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { penceToPound } from '../utils/penceToPound';
import { convertToBST } from '../utils/dateTimeFormat';

import inspections from '../images/inspections.png';
import { InspectionTap } from '../queries/journeyQuery';

const BORDER_COLOR_JOURNEY_INCOMPLETE = '#ff3f3f';
const TIME = '#858585';
const COL1_WIDTH = 5;
const COL2_WIDTH = 55;
const COL3_WIDTH = 30;
const COL4_WIDTH = 10;

const styles = StyleSheet.create({
  tableRowJourneyInspection: {
    margin: 'auto',
    flexDirection: 'row',
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: BORDER_COLOR_JOURNEY_INCOMPLETE,
    marginBottom: 10
  },
  tableCol1Header: {
    width: COL1_WIDTH + '%'
  },
  tableCol2Header: {
    width: COL2_WIDTH + '%'
  },
  tableCol3Header: {
    width: COL3_WIDTH + '%'
  },
  tableCol4Header: {
    width: COL4_WIDTH + '%',
    textAlign: 'right'
  },
  tableCell: {
    margin: 10,
    fontSize: 10
  },
  image: {
    height: 12,
    width: 12
  },
  time: {
    color: TIME
  }
});

const PdfInspection = (props: { inspection: InspectionTap }) => {
  if (props.inspection.finalOutstandingBalance > 0) {
    return (
      <View
        data-testid="inspectionItem"
        style={styles.tableRowJourneyInspection}
      >
        <View style={styles.tableCol1Header}>
          <Text style={styles.tableCell}>
            <Image
              data-testid="inspectionImage"
              style={styles.image}
              src={inspections}
            />
          </Text>
        </View>
        <View style={styles.tableCol2Header}>
          <Text data-testid="inspectionTime" style={styles.tableCell}>
            <Text>Standard fare</Text>
            {'  '}
            <Text style={styles.time}>
              {`${convertToBST(props.inspection.dateTime).format('HH:mm')}`}
            </Text>
          </Text>
        </View>
        <View style={styles.tableCol3Header}>
          <Text style={styles.tableCell}></Text>
        </View>
        <View data-testid="inspectionBalance" style={styles.tableCol4Header}>
          <Text style={styles.tableCell}>
            {`£${penceToPound(props.inspection.finalOutstandingBalance)}`}
          </Text>
        </View>
      </View>
    );
  }

  return null;
};

export default PdfInspection;
