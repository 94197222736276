import { makeStyles } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AlertTitle } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import {
  ApolloClient,
  NormalizedCacheObject,
  useLazyQuery,
} from '@apollo/client';
import { History } from 'history';
import React, { useEffect, useRef, useState } from 'react';
import Iframe from './Iframe';
import IframeMock from './IframeMock';
import NavHeader from './NavHeader';
import { SessionTimeouts } from '../App';
import { client } from '../libs/client';
import { NONCE_QUERY, NonceQuery } from '../queries/nonceQuery';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fafafa'
  },
  title: {
    fontWeight: 50,
    paddingBottom: 10
  },
  subheading: {
    fontWeight: 50
  },
  landingPageInfo: {
    paddingBottom: 24
  },
  alert: {
    marginBottom: 30,
    borderRadius: '0px',
    color: 'rgba(0, 0, 0, 0.87)',
    textAlign: 'left'
  },
  alertTitle: {
    fontWeight: 600
  },
  alertMessage: {
    flex: 1 /* IE11 fix */
  }
}));

export interface CardInputProps {
  history: History;
  sessionExpired: boolean;
  setIsAuthorised: React.Dispatch<React.SetStateAction<boolean>>;
  setAuthToken: React.Dispatch<React.SetStateAction<string>>;
  setDisplayTimeoutDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setSessionExpired: React.Dispatch<React.SetStateAction<boolean>>;
  setSessionTimeouts: React.Dispatch<React.SetStateAction<SessionTimeouts | null>>;
}

const CardInput = (props: CardInputProps) => {
  const classes = useStyles();
  const [islocal, setIsLocal] = useState(false);
  // this is to make the iframe reload if the nonce doesn't propagate in time
  const [seq, setSeq] = useState(0);
  const [nonceNotPropagated, setNonceNotPropagated] = useState(false);

  const clientRef = useRef<ApolloClient<NormalizedCacheObject>>();

  const [checkNoncePropagated, { loading, data }] = useLazyQuery<NonceQuery>(
    NONCE_QUERY,
    {
      errorPolicy: 'all',
      client: clientRef.current
    }
  );

  useEffect(() => {
    if (
      ['localhost', '127.0.0.1'].includes(window.location.hostname) ||
      process.env.REACT_APP_MOCK_IFRAME === 'true'
    ) {
      setIsLocal(true);
    }
    window.addEventListener('message', (event) => {
      if (event.data.event_id === 'auth_id') {
        props.setAuthToken(event.data.auth_token);
        props.setIsAuthorised(true);
        props.setSessionExpired(false);
        clientRef.current = client({
          authorization: event.data.auth_token
        });
        checkNoncePropagated();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      if (data) {
        if (data.Nonce.isPropagated) {
          props.history.push({
            pathname: '/journey',
            search: window.location.search
          });

          // we need to store these in state so they can be cancelled if the user
          // navigates back to the auth screen via the back arrow
          props.setSessionTimeouts({
            expiryWarningTimeout: setTimeout(
              () => props.setDisplayTimeoutDialog(true),
              Number(process.env.REACT_APP_SESSION_EXPIRY_WARNING_MINS || 25) *
                1000 *
                60
            ),
            expiryTimeout: setTimeout(() => {
              props.setDisplayTimeoutDialog(false);
              props.setSessionExpired(true);
              props.history.push('/');
            }, Number(process.env.REACT_APP_SESSION_EXPIRY_MINS || 30) * 1000 * 60)
          });
        } else {
          setSeq(seq + 1);
          setNonceNotPropagated(true);
        }
      }

      return () => {};
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, props.history]
  );

  return (
    <div className="CardInput">
      <NavHeader />
      <h1 data-test="title" data-testid="navTitle" className={classes.title}>
        See your contactless journey history
      </h1>
      <div className={classes.landingPageInfo}>
        <h2 data-testid="canHeading" className={classes.subheading}>
          You can:
        </h2>
        <ul data-testid="canList">
          <li>
            View travel within the <strong>last 12 months</strong>
          </li>
          <li>Download statements into a PDF</li>
          <li>Query charges if you have a valid reason</li>
        </ul>
        <h2 data-testid="needHeading" className={classes.subheading}>
          You will need:
        </h2>
        <ul data-testid="needList">
          <li>
            The long number from your payment card / card linked to your payment
            device (watch, smartphone)
          </li>
        </ul>
      </div>
      {nonceNotPropagated && (
        <Alert
          classes={{
            root: classes.alert,
            message: classes.alertMessage
          }}
          severity="error"
          variant="outlined"
          tabIndex={0}
        >
          <AlertTitle className={classes.alertTitle}>
            Sorry, there is a problem
          </AlertTitle>
          It looks like something went wrong on our side. We're working on it,
          so please try again later.
        </Alert>
      )}
      {props.sessionExpired && (
        <Alert
          classes={{
            root: classes.alert,
            message: classes.alertMessage
          }}
          severity="info"
          variant="outlined"
          tabIndex={0}
        >
          <AlertTitle className={classes.alertTitle}>
            Sorry, your session has expired
          </AlertTitle>
          To continue, please enter your card number again.
        </Alert>
      )}
      {islocal ? <IframeMock key={seq} /> : <Iframe key={seq} />}
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default CardInput;
