import gql from 'graphql-tag';

export interface NonceQuery {
  Nonce: Nonce;
}

interface Nonce {
  isPropagated: boolean;
}

export const NONCE_QUERY = gql`
  query NonceQuery {
    Nonce {
      isPropagated
    }
  }
`;
