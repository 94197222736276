const WHISPER_WAIT_TIMEOUT = 30;

const createdCallback = (err, frame) => {
  if (!err) {
    // IFrame has been created
  }
  // console.log(`createdCallback called.`);
};

const customStyles = {
  div_whisper_iframe: 'height:300px;margin-left:-15px;border:none;',
  whisper_submit:
    'background-color:rgb(58,58,58);border:none;border-radius:0px',
  whisper_form: 'padding-left:0;padding-right:0;margin-left:0;margin-right:0'
};

const customLabels = {
  card_number: {
    label: 'Card number',
    placeholder: ' ',
    help_text: 'Enter the long number as it appears on your payment card'
  }
};

const customMessages = {
  201: 'Your card details have been validated',
  400: 'An error occurred while attempting to validate your card details',
  403: 'Service error : Cannot communicate with validation service',
  503: 'Service error : The service is currently unavailable'
};

const ensureScriptLoaded = () => {
  return new Promise(function(resolve, reject) {
    (function waitForWhisper() {
      if (window.Whisper) {
        registerFrame();
        return resolve();
      }
      setTimeout(waitForWhisper, WHISPER_WAIT_TIMEOUT);
    })();
  });
};

const registerFrame = () => {
  const content = window.parent;
  window.Whisper.RegisterFrame(
    {
      publishableKey: process.env.REACT_APP_VIX_IFRAME_KEY,
      container: 'whisper-registration-region',
      layout: 'narrow',
      callbackMode: 'callback',
      style: customStyles,
      labels: customLabels,
      messages: customMessages,
      success: function(nonce) {
        content.postMessage(
          {
            event_id: 'auth_id',
            auth_token: nonce
          },
          '*'
        );
      },
      failure: function(error) {
        console.log('failure : error returned : ' + error);
      }
    },
    createdCallback
  );
};

export const registerFrameWhisper = () => {
  ensureScriptLoaded();
};
