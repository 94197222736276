import gql from 'graphql-tag';

export interface JourneyResponse {
  Journey: {
    Data: JourneyData;
  }
}

export interface JourneyData {
  lastFourDigits: string;
  expiryDate: string;
  startDate: string;
  endDate: string;
  cardType: string;
  tokens: Token[];
}
export interface Token {
  lastFourDigits: string;
  expiryDate: string;
  cardType: string;
  token: string;
  parentTokenLastFourDigits: string;
  parentTokenExpirationDate: string;
  parentTokenCardType: string;
  parentToken: string;
  formFactor: string;
  lastPaymentActivityDate: string;
  lastTapOnDate: string;
  lastTapOnLocation: string;
  travelSummaries: TravelSummary[];
  inspectionTaps: InspectionTap[];
}

export interface TravelSummary {
  operationalDay: number;
  primarySettlementDate: string;
  latestSettlementDate: string;
  latestJourneys: Journey[];
  settlementHistory: Settlement[];
}

export interface Journey {
  fareRequestIdentifier: string;
  fareId: string;
  originalAmount: number;
  finalAmount: number;
  cappingAmount: number;
  zoneMin: number;
  zoneMax: number;
  traversedZones: string;
  isCapReach: Boolean;
  isDefaultFare: Boolean;
  isPeak: Boolean;
  fareDescription: string;
  tapOn: Tap;
  tapOff: Tap;
  status: string;
  totalValueRefunded: number;
  finalOutstandingBalance: number;
  isNotionalDebt: Boolean;

  // this field doesn't exist in the data yet we're using it in places
  // I cannot see where this is being set otherwise
  refund: boolean;
}

export interface Tap {
  location: string;
  dateTime: string;
  zone: string;
  isLate: Boolean;
  isIncomplete: Boolean;
  isDeny: Boolean;
}

export interface Settlement {
  dateTime: string;
  amount: number;
  status: string;
  type: string;
  reason: string;
  journeys: SettlementJourney[];
  isNotionalDebt: Boolean;
}

export interface SettlementJourney {
  fareRequestIdentifier: string;
  valueRefunded: number;
}

export interface InspectionTap {
  dateTime: string;
  device: string;
  totalValueRefunded: number;
  finalOutstandingBalance: number;
  process: Process[];
}

export interface Process {
  status: string;
  outcome: string;
  fundRequestIdentifier: string;
  settlement: Settlement;
}

export const JOURNEY_QUERY = gql`
  query JourneyQuery($startDate: String!, $endDate: String!) {
    Journey(startDate: $startDate, endDate: $endDate) {
      Data {
        lastFourDigits
        tokens {
          lastFourDigits
          expiryDate
          cardType
          token
          parentTokenLastFourDigits
          parentTokenExpirationDate
          parentTokenCardType
          parentToken
          formFactor
          travelSummaries {
            latestJourneys {
              fareRequestIdentifier
              fareId
              totalValueRefunded
              traversedZones
              fareDescription
              finalAmount
              tapOn {
                location
                dateTime
                zone
              }
              tapOff {
                location
                dateTime
                zone
              }
            }
            settlementHistory {
              dateTime
              type
              status
              journeys {
                fareRequestIdentifier
                valueRefunded
              }
            }
          }
          inspectionTaps {
            dateTime
            finalOutstandingBalance
          }
        }
      }
    }
    Version
  }
`;
