import { useLazyQuery } from '@apollo/client';
import React, { useRef, useState } from 'react';
import EnquiryForm from './EnquiryForm';
import { JourneyData } from './JourneyData';
import { SessionTimeouts } from '../App';
import { client } from '../libs/client';
import { GroupedJourneys } from '../utils/groupJourneysByDay';
import { SelectedDates } from '../types';
import { JOURNEY_QUERY, JourneyResponse } from '../queries/journeyQuery';

interface JourneyProps {
  authToken: string;
  sessionTimeouts: SessionTimeouts | null;
  displayTimeoutDialog: boolean;
  sessionExpired: boolean;
}

const Journey: React.FC<JourneyProps> = (props) => {
  const [age, setAge] = useState('');
  const [selectedDates, setSelectedDates] = useState<SelectedDates>();
  const [
    enquiryJourneys,
    setEnquiryJourneys
  ] = useState<GroupedJourneys | null>(null);

  const clientRef = useRef(client({ authorization: props.authToken }));

  const [
    fetchJourneyData,
    { error, loading, data }
  ] = useLazyQuery<JourneyResponse>(JOURNEY_QUERY, {
    errorPolicy: 'all',
    client: clientRef.current
  });

  console.log('data', data);

  // filter out any potential tokens without journeys or inspections as there's nothing to see here
  // and also sort by formFactor for the multi-token selection/dropdown.
  if (data && data.Journey && data.Journey.Data && data.Journey.Data.tokens) {
    data.Journey.Data.tokens = data.Journey.Data.tokens.filter(
      (token) => {
        return (
          token.inspectionTaps.length > 0 || token.travelSummaries.length > 0
        );
      }
    ).sort((a, b) =>
      a.formFactor === 'card' ? 1 : -1
    ).reverse();
  }

  if (enquiryJourneys) {
    return (
      <EnquiryForm
        authToken={props.authToken}
        groupedJourneys={enquiryJourneys}
        onBackButtonClick={() => {
          setEnquiryJourneys(null);
        }}
      />
    );
  } else {
    return (
      <JourneyData
        age={age}
        setAge={setAge}
        selectedDates={selectedDates}
        onDateSelection={(startDate: string, endDate: string) => {
          setSelectedDates({
            startDate,
            endDate
          });
        }}
        fetchJourneyData={fetchJourneyData}
        error={error}
        data={data}
        setEnquiryJourneys={setEnquiryJourneys}
        loading={loading}
        sessionTimeouts={props.sessionTimeouts}
        displayTimeoutDialog={props.displayTimeoutDialog}
        sessionExpired={props.sessionExpired}
      />
    );
  }
};

export default Journey;
