import { makeStyles } from '@material-ui/core';
import moment from 'moment-timezone';
import React, { Fragment } from 'react';
import groupJourneysBy7Day from '../utils/groupJourneysBy7Day';
import fareDescription from '../libs/fareDescription';
import WeeklyExpansionPanelContainer from './WeeklyExpansionPanelContainer';
import { JourneyData } from '../queries/journeyQuery';

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '1em'
  },
  weeklyTicketsText: {
    textAlign: 'left',
    fontSize: '1rem'
  },
  weeklyTicketsTextHeader: {
    fontSize: '1.2rem'
  },
  boxHeader: {
    display: 'inline-block',
    border: '1px solid black',
    padding: '10px'
  },
  boxSection: {
    display: 'inline-block',
    padding: '10px'
  }
}));

const WeeklyTickets = (props: {
  journeyData: JourneyData;
  setEnquiryJourneys: any;
}) => {
  const classes = useStyles();
  const journeysGroupedBy7Day: any = props.journeyData
    ? groupJourneysBy7Day(props.journeyData.tokens[0].travelSummaries)
    : [];
  let sortedWeekKeys: any[] = [];

  if (journeysGroupedBy7Day) {
    sortedWeekKeys = Object.keys(journeysGroupedBy7Day).sort(
      (a, b) => moment(a).unix() - moment(b).unix()
    );
  }

  return (
    <div
      id="weekTickets"
      data-test="weeklyTicketsText"
      className={classes.weeklyTicketsText}
    >
      {sortedWeekKeys.map((weekKey) =>
        journeysGroupedBy7Day[weekKey].map(
          (ticketJourneysByDay: any[], idx: number) => {
            const firstJourney = ticketJourneysByDay[0][0];
            const uniqueKey = `${weekKey}:${firstJourney.fareDescription}:${firstJourney.fareRequestIdentifier}`;

            return (
              <WeeklyExpansionPanelContainer
                index={idx}
                key={uniqueKey}
                fareDescription={fareDescription(firstJourney.fareDescription)}
                weekStartDate={weekKey}
                setEnquiryJourneys={props.setEnquiryJourneys}
                ticketJourneysByDay={ticketJourneysByDay}
              />
            );
          }
        )
      )}
      {journeysGroupedBy7Day &&
        Object.keys(journeysGroupedBy7Day).length === 0 && (
          <Fragment>
            <div className={classes.boxHeader}>
              <p>
                <strong
                  className={classes.weeklyTicketsTextHeader}
                  data-testid="cappedDetailsTitle"
                >
                  No Weekly capped fares found
                </strong>
              </p>
              <p>
                You have not reached any weekly caps for the selected card and
                date range.
              </p>
            </div>
            <div className={classes.boxSection}>
              <p>
                <strong
                  className={classes.weeklyTicketsTextHeader}
                  data-testid="cappedDetailsSubtitle"
                >
                  How do contactless weekly caps work?
                </strong>
              </p>
              <p>
                If you travel on multiple days during the week (Monday to
                Sunday), we’ll charge for each day until the total reaches the
                weekly cap for the zones you’ve travelled through.
              </p>
              <p>
                The rest of your journeys in those zones will be{' '}
                <strong>free</strong> until the end of the week.
              </p>
              <p>
                For more information on contactless weekly capped fares and
                pricing, please click{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://tfgm.com/contactless"
                >
                  here
                </a>
                .
              </p>
            </div>
          </Fragment>
        )}
    </div>
  );
};

export default WeeklyTickets;
